import React, {
  Children, createRef, useEffect, useState,
} from 'react';
import './DraggableSlider.css';

interface PropsContainer {
  activeSlideToggler: any
}

/**
 * A draggable slider with dynamic active slide detection
 */
const DraggableSlider: React.FC<PropsContainer> = ({ activeSlideToggler, children }) => {
  /**
   ** Params ==============================================
   */
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [startX, setStartX] = useState(0);
  const [startSlide, setStartSlide] = useState(0);
  const display: React.RefObject<HTMLDivElement> = createRef();
  const inner: React.RefObject<HTMLDivElement> = createRef();
  const count = Children.count(children);

  useEffect(() => {
    moveToActiveSlide();
  }, []);

  /**
   ** Methods =============================================
   */

  /**
   * Move slider to slide 0 at first render
   */
  useEffect(() => {
    if (isFirstRender) {
      moveToActiveSlide();
      setIsFirstRender(false);
    }
  });

  /**
   * Get the index of the slide which is closest to the center of the slider
   *
   * @returns {Number} the index of the slide
   */
  const getActiveSlide = () => {
    let res = 0;
    let i = 1;
    while (i < count) {
      if (Math.abs(getSlideOffset(res)) > Math.abs(getSlideOffset(i))) res = i;
      i += 1;
    }
    return res;
  };

  /**
   * Get the absolute horizontal offset of a slide from the slider's display center point
   *
   * @param {Number} slide the index of the slide
   * @returns {Number} the absolute of the offset in pixels
   */
  const getSlideOffset = (slide: number) => {
    if (!display.current || !inner.current) return 0;

    const innerWidth = inner.current.offsetWidth;
    const displayWidth = display.current.offsetWidth;
    const slideWidth = innerWidth / count;

    const slideCenter = slide * slideWidth + slideWidth / 2;
    const displayCenter = displayWidth / 2;

    return slideCenter + offsetX - displayCenter;
  };

  /**
   * Initialize the active state of the slider
   * @param {any} e a MouseDown or TouchStart event
   */
  const onStart = (e: any) => {
    setIsActive(true);
    setStartX(e.pageX || e.touches[0].pageX);
    setStartSlide(activeSlide);
  };

  /**
   * Remove the active state of the slider
   */
  const onEnd = () => {
    setIsActive(false);
    if (activeSlide !== startSlide) moveToActiveSlide();
    if (getSlideOffset(0) > 0 || getSlideOffset(count - 1) < 0) moveToActiveSlide();
  };

  /**
   * Get the touch / mouse move distance and move the slider inner
   *
   * @param {Event} e a MouseMove our TouchMove event
   */
  const onMove = (e: any) => {
    if (!isActive) return;
    const pos: number = e.pageX || e.touches[0].pageX;
    const dist: number = pos - startX;
    setStartX(pos);
    setOffsetX(offsetX + dist);
    setActiveSlide(getActiveSlide());
    activeSlideToggler(activeSlide);
  };

  /**
   * Move the slider to the active slide
   */
  const moveToActiveSlide = () => {
    if (activeSlide == null) {
      setActiveSlide(0);
      activeSlideToggler(activeSlide);
    }
    moveToSlide(activeSlide);
  };

  /**
   * Move the slider to a specified slide, to the last one if the number is greater than the
   * amount of slides or to the first one if the number is inferior to zero.
   *
   * @param {number} i the index of the slide
   */
  const moveToSlide: any = (i: number) => {
    let index = i;

    // Edge cases
    if (index < 0) index = 0;
    if (index > count - 1) return moveToSlide(count - 1);

    // Update active slide and active classes
    setActiveSlide(index);
    activeSlideToggler(activeSlide);

    // Move the slider
    setOffsetX(offsetX - getSlideOffset(index));

    return 0;
  };

  /**
   ** Render ==============================================
   */
  return (
    <div
      ref={display as React.RefObject<HTMLDivElement>}
      className={`draggable-slider${isActive ? ' active' : ''}`}
      onMouseDown={(e: any) => onStart(e)}
      onTouchStart={(e: any) => onStart(e)}
      onMouseLeave={() => onEnd()}
      onMouseUp={() => onEnd()}
      onTouchEnd={() => onEnd()}
      onMouseMove={(e: any) => onMove(e)}
      onTouchMove={(e: any) => onMove(e)}
    >
      <div
        ref={inner as React.RefObject<HTMLDivElement>}
        className="inner"
        style={{ transform: `translateX(${offsetX}px)` }}
      >
        {children}
      </div>
    </div>

  );
};

export default DraggableSlider;
