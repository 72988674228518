/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AvailableLanguages from '../../model/enums/AvailableLanguages';

const initialState = {
  firebaseUser: null,
  user: null,
  family: null,
  role: null,
  language: AvailableLanguages.DEFAULT,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    updateFirebaseUser(state, action: PayloadAction<any>) {
      state.firebaseUser = action.payload;
    },
    updateFamily(state, action: PayloadAction<any>) {
      state.family = action.payload;
    },
    updateRole(state, action: PayloadAction<any>) {
      state.role = action.payload;
    },
    updateLanguage(state, action: PayloadAction<any>) {
      state.language = action.payload;
    },
  },
});

export const {
  updateUser,
  updateFamily,
  updateFirebaseUser,
  updateRole,
  updateLanguage,
} = userSlice.actions;

export default userSlice;
