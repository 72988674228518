import { Modal, CloseButton } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import './CustomModal.css';

interface ContainerProps {
  show: boolean,
  title: string,
  toggle: () => any
}

const CustomModal: React.FC<ContainerProps> = ({
  show, title, children, toggle,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (show) setShowModal(true);
    else setShowModal(false);
  }, [show]);

  return (
    <Modal
      show={showModal}
      centered
      backdropClassName="mtp-modal-backdrop"
      size='lg'
      onHide={() => { toggle(); }}
      dialogClassName="mtp-modal-dialog"
    >

      <Modal.Header >
        <Modal.Title>{title}</Modal.Title>
        <CloseButton onClick={() => toggle()}/>
      </Modal.Header>
      <Modal.Body>
        {
          children
        }
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
