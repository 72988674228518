import { auth } from '../FirebaseConfig';

/** Manages tasks related to email sending */
class EmailManager {
  private publicUrl: string = process.env.REACT_APP_PUBLIC_URL as string;

  /**
   * Send account confirmation email to the user
   */
  sendAccountConfirmationEmail = async () => {
    if (!auth.currentUser) throw new Error('user is undefined!');
    auth.currentUser.sendEmailVerification({ url: `${this.publicUrl}/dashboard?from=accountConfirmed` });
  };

  /**
   * Send password reset email to the user
   *
   * @param {string} email
   */
  sendPasswordResetEmail = (email: string) => {
    if (email === '' || email === null) throw new Error('email not specified!');
    auth.sendPasswordResetEmail(email, { url: `${this.publicUrl}/login?from=passwordReset` });
  };
}

const emailManager = new EmailManager();
export default emailManager;
