import firebase from 'firebase/app';
import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import * as Sentry from '@sentry/react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { Select, TextField } from 'formik-mui';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from 'formik-mui-lab';
import { Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, MenuItem } from '@mui/material';
import userManager from '../../services/UserManager';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import CustomModal from '../../component/customModal/CustomModal';
import './Dashboard.css';
import Spinner from '../../component/spinner/Spinner';
import Sidebar from '../../component/sidebar/Sidebar';
import Avatars from '../../model/enums/Avatars';
import MtpButton from '../../component/mtpButton/mtpButton';
import utils from '../../services/Utils';
import translator from '../../assets/translator.json';
import FamilyRoles from '../../model/enums/FamilyRoles';
import Spacer from '../../component/spacer/Spacer';
import alertUtils from '../../services/AlertUtils';
import familyManager from '../../services/FamilyManager';
import FamilyMember from '../../model/FamilyMember';
import stripeManager from '../../services/StripeManager';
import PricingPlans from '../../model/enums/PricingPlans';
import stravaManager from '../../services/StravaManager';
import emailManager from '../../services/EmailManager';
import StravaAthlete from '../../model/StravaAthlete';
import { ReactComponent as StarIcon } from '../../assets/img/star-solid.svg';
import PromptForCredentials from '../../component/promptForCredentials/PromptForCredentials';
import Gender from '../../model/enums/Gender';
import Family from '../../model/Family';

const Dashboard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const firebaseUser = useAppSelector((state) => state.user.firebaseUser) as firebase.User;
  const language = useAppSelector((state) => state.user.language);
  const role = useAppSelector((state) => state.user.role) as string | null;

  const [busy, setBusy] = useState(false);
  const [family, setFamily] = useState(undefined as unknown as Family);
  const [showAccountCreatedModal, setShowAccountCreatedModal] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showReauthenticateModal, setShowReauthenticateModal] = useState(false);
  const [showStravaAssociationModal, setShowStravaAssociationModal] = useState(false);
  const [showStravaConfirmationModal, setShowStravaConfirmationModal] = useState(false);
  const [newAvatarPath, setNewAvatarPath] = useState(user.avatarPath);
  const [showPassword, setShowPassword] = useState(false);
  const [stravaAthlete, setStravaAthlete] = useState<StravaAthlete | null>(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /** Manage alerts on load */
  useEffect(() => {
    if (!user) navigate('/login');
    setBusy(true);
    familyManager.getFamilyById(user.familyId).then((fetchedFamily) => setFamily(fetchedFamily));
    handleFromParam().then(() => {
      setBusy(false);
    });
  }, [user]);

  const toggleEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const togglePasswordModal = () => {
    setShowPasswordModal(!showPasswordModal);
  };

  const toggleAvatarModal = () => {
    setShowAvatarModal(!showAvatarModal);
  };

  const toggleDeleteAccountModal = () => {
    setShowDeleteAccountModal(!showDeleteAccountModal);
  };

  const toggleAccountCreatedModal = () => {
    setShowAccountCreatedModal(!showAccountCreatedModal);
  };

  const toggleReauthenticateModal = () => {
    setShowReauthenticateModal(!showReauthenticateModal);
  };

  const toggleStravaAssociationModal = () => {
    setShowStravaAssociationModal(!showStravaAssociationModal);
  };

  const toggleStravaConfirmationModal = () => {
    setShowStravaConfirmationModal(!showStravaConfirmationModal);
  };

  const handleFromParam = async () => {
    const fromParam = searchParams.get('from');
    switch (fromParam) {
      case 'accountConfirmed':
        await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.accountConfirmed), dispatch);
        break;
      case 'accountCreated':
        setShowAccountCreatedModal(true);
        return;
      case 'passwordReset':
        await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.passwordReset), dispatch);
        break;
      case 'associateStrava':
        if (searchParams.get('error')) {
          navigate('/dashboard');
          return;
        }
        await handleAuthorizeUser(searchParams.get('code'), searchParams.get('scope'));
        break;
      default:
        break;
    }
    await utils.verifyCheckoutSession(user);
  };

  const handleCancelStravaAssociation = async () => {
    try {
      setBusy(true);
      if (stravaAthlete === null) throw new Error('There is no Strava Athlete to deauthorize!');
      await stravaManager.deauthorizeAthlete(stravaAthlete.id);
      setShowStravaAssociationModal(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'handleCancelStravaAssociation', requestName: error.details?.requestName };
      await alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotCancel), dispatch);
      Sentry.captureException(error, { extra: details });
    } finally {
      navigate('/dashboard');
      setBusy(false);
    }
  };

  const handleConfirmStravaAssociation = async () => {
    try {
      setBusy(true);
      if (stravaAthlete === null) throw new Error('There is no Strava Athlete to deauthorize!');
      await userManager.updateUserData(user.userId, { stravaAthleteId: stravaAthlete.id });
      navigate('/dashboard');
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'handleConfirmStravaAssociation', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotConfirm), dispatch);
      Sentry.captureException(error, { extra: details });
    } finally {
      navigate('/dashboard');
      setBusy(false);
    }
  };

  /**
   * Handles deletion of a User account:
   *
   * - Deletes the Family owned by the User.
   * - Cancels the active Stripe Subscription.
   * - Deletes the User from Firebase Firestore & Auth.
   */
  const deleteAccount = async () => {
    try {
      setBusy(true);

      const ownedFamily = await familyManager.getFamilyByOwner(user.userId);
      // User is in his owned family
      if (ownedFamily && user.familyId === ownedFamily.id) {
        const members: FamilyMember[] = await familyManager.getFamilyMembers(ownedFamily.id);
        await Promise.all(
          members.filter((member) => member.userId !== user.userId)
            .map(async (member: FamilyMember) => {
              const newFamily = await familyManager.joinOwnedFamily(member.userId);
              await familyManager.deleteFamilyMember(member.userId, family.id);
              await userManager.updateUserData(member.userId, { familyId: newFamily.id });
            }),
        );
      } else {
        await familyManager.deleteFamilyMember(user.userId, user.familyId);
      }

      if (user.stripeCustomer && user.stripeCustomer.customerId) {
        const subscriptions = await stripeManager.listStripeSubscriptions();
        await Promise.all(subscriptions.map((subscription) => stripeManager.cancelStripeSubscription(subscription.id)));
      }

      if (user.stravaAthleteId) await stravaManager.deauthorizeUser(user.userId);
      if (user.familyId === ownedFamily?.id) {
        await familyManager.deleteFamily(user.familyId);
      }
      await userManager.deleteFirestoreUser(user.userId);
      await userManager.deleteFirebaseUser(firebaseUser);

      navigate('/login');
    } catch (error: any) {
      setBusy(false);
      console.error(error);

      if (error.code === 'auth/requires-recent-login') {
        setShowReauthenticateModal(true);
      } else {
        const details = { component: 'Dashboard', action: 'deleteAccount', requestName: error.details?.requestName };
        alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotDelete), dispatch);
        Sentry.captureException(error, { extra: details });
      }
    }
  };

  const updateUser = async (val: any) => {
    try {
      setBusy(true);
      await userManager.updateUserData(
        user.userId,
        {
          firstName: val.firstName,
          name: val.name,
          birthDate: utils.formatDate(new Date(val.birthDate)),
          gender: val.gender,
        },
      );
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.profileUpdated), dispatch);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'updateUser', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotUpdate), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  const updateUserEmail = async (val: any) => {
    try {
      setBusy(true);
      const newUser = {
        ...user,
      };
      newUser.email = val.newEmail;
      await userManager.updateUserEmail(newUser);
      await userManager.updateUserData(user.userId, { email: val.newEmail });
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.profileUpdated), dispatch);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);

      if (error.code === 'auth/requires-recent-login') {
        setShowReauthenticateModal(true);
      } else {
        const details = { component: 'Dashboard', action: 'updateUserEmail', requestName: error.details?.requestName };
        alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotUpdate), dispatch);
        Sentry.captureException(error, { extra: details });
      }
    }
  };

  /**
   * Handler that updates the current User's password with values from the Reset Password form.
   *
   * @param {*} values The values entered in the Reset Password form.
   */
  const resetPassword = async (values: any) => {
    try {
      setBusy(true);
      await userManager.updatePassword(values.newPassword);
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.profileUpdated), dispatch);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      if (error.code === 'auth/requires-recent-login') {
        setShowReauthenticateModal(true);
      } else {
        const details = { component: 'Dashboard', action: 'resetPassword', requestName: error.details?.requestName };
        alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotReset), dispatch);
        Sentry.captureException(error, { extra: details });
      }
    }
  };

  /**
   * Handler that updates the user avatar with the one selected on the modal.
   */
  const updateUserAvatar = async () => {
    try {
      setShowAvatarModal(false);
      setBusy(true);
      await userManager.updateUserData(user.userId, { avatarPath: newAvatarPath });
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.profileUpdated), dispatch);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'updateUserAvatar', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotUpdate), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  /**
   * Handler that resends the confirmation email to the current user.
   */
  const resendConfirmationEmail = async () => {
    try {
      setBusy(true);
      await emailManager.sendAccountConfirmationEmail();
      setBusy(false);
      alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.confirmationEmailResent), dispatch);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'resendConfirmationEmail', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.tryAgain), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  const handleAuthorizeUser = async (code: string | null, scope: string | null) => {
    try {
      if (code === null) throw new Error('code is null');
      if (scope === null) throw new Error('scope is null');
      setBusy(true);
      const stravaAthleteTemp = await stravaManager.authorizeUser(user.userId, code, scope);
      setStravaAthlete(stravaAthleteTemp);
      setShowStravaConfirmationModal(true);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'handleAuthorizeUser', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.tryAgain), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  const dissasociateStrava = async () => {
    try {
      if (!user.stravaAthleteId) throw new Error('User has no Strava athlete associated!');
      setBusy(true);
      await stravaManager.deauthorizeUser(user.userId);
      navigate('/dashboard');
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Dashboard', action: 'disassociateStrava', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.tryAgain), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div className="content">
      <Sidebar isMenu />
      <Spinner show={busy} />
      <section className="main-content">
        <div className="dashboard__card dashboard__profil-card ">
          <div className='dashboard__card-avatar-col'>
            <h1 className="capitalize">{user?.firstName} {user?.name}</h1>
            <div className='dashboard__card-avatar'>
              <img src={`./${user.avatarPath || 'assets/avatars/avatar_none.png'}`} alt="avatar" />
            </div>
            <p onClick={() => toggleAvatarModal()}>
              {utils.getTranslation(language, translator.pages.dashboard.changeAvatar)}
            </p>
            {
              /**
               * If the User is in his own Family and it's on the Free Pricing Plan,
               * render the Upgrade Plan button.
               */
              (user?.userId === family?.owner && family?.plan === PricingPlans.FREE)
              && <MtpButton
                expand='block'
                color='mtp-yellow'
                onClick={() => navigate('/choose-plan')}>
                <div style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px',
                }}>
                  <div style={{ width: '20px', marginBottom: '5px' }}>
                    <StarIcon fill="#fff" fontSize='15px' />
                  </div>
                  {utils.getTranslation(language, translator.pages.dashboard.upgradePlan)}
                </div>
              </MtpButton>
            }
          </div>
          <div className='dashboard__card-info-col'>
            <Formik
              initialValues={{
                name: user?.name,
                firstName: user?.firstName,
                birthDate: new Date(user?.birthDate?.replace(/-/g, '/')),
                email: user?.email.replace('@movethenplay.com', ''),
                password: '',
                passwordConfirmation: '',
                gender: user?.gender,
              }}
              validationSchema={yup.object({
                name: yup.string()
                  .required(utils.getTranslation(language, translator.formMessages.requiredField)),
                birthDate: yup.date().typeError(utils.getTranslation(language, translator.formMessages.invalidDate))
                  .required(utils.getTranslation(language, translator.formMessages.requiredField)),
              })}
              onSubmit={(val, { setSubmitting }) => {
                updateUser(val);
                setSubmitting(false);
              }}>
              {
                (props) => (
                  <Form>
                    <div className="mtp-form-container">
                      <Field
                        component={TextField}
                        className="input-capitalize"
                        name="firstName"
                        label={utils.getTranslation(language, translator.models.user.firstname)}
                        margin="normal" />
                      <Spacer height={5}
                      />
                      <Field
                        component={TextField}
                        className="input-capitalize"
                        name="name"
                        label={utils.getTranslation(language, translator.models.user.name)}
                      />
                      <Spacer height={5} />
                      <Field
                        component={Select}
                        as="select"
                        name="gender"
                        type="select"
                        label={utils.getTranslation(language, translator.pages.authPages.register.createAccount.gender)}
                        className="gender-input"
                      >
                        <MenuItem value={Gender.MALE}>
                          {utils.getTranslation(language, translator.pages.authPages.register.createAccount.genderType.male)}
                        </MenuItem>
                        <MenuItem value={Gender.FEMALE}>
                          {utils.getTranslation(language, translator.pages.authPages.register.createAccount.genderType.female)}
                        </MenuItem>
                        <MenuItem value={Gender.UNSPECIFIED}>
                          {utils.getTranslation(language, translator.pages.authPages.register.createAccount.genderType.unspecified)}
                        </MenuItem>
                      </Field>
                      <Spacer height={5} />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Field
                          component={DatePicker}
                          name="birthDate"
                          label={utils.getTranslation(language, translator.models.user.birthDate)}
                          margin="normal" />
                      </LocalizationProvider>
                      <Spacer height={5} />
                      <Field
                        component={TextField}
                        name="email"
                        label={utils.getTranslation(language, translator.models.user[user?.email.includes('@movethenplay.com') ? 'username' : 'email'])}
                        margin="normal"
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="change your email"
                                onClick={() => toggleEmailModal()}
                                onMouseDown={(e: any) => e.preventDefault()}
                              >
                                <Edit />
                              </IconButton>
                            </InputAdornment>

                          ),
                        }} />
                      {!firebaseUser.emailVerified
                        && <div className="mtp-resend-email-cta" onClick={resendConfirmationEmail}>
                          {utils.getTranslation(language, translator.pages.dashboard.resendConfirmationEmail)}</div>}
                      <Field
                        component={TextField}
                        name="password"
                        label={utils.getTranslation(language, translator.models.user.password)}
                        margin="normal"
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="change your password"
                                onClick={() => togglePasswordModal()}
                                onMouseDown={(e: any) => e.preventDefault()}>
                                <Edit />
                              </IconButton>
                            </InputAdornment>

                          ),
                        }}
                      />
                      <Spacer height={10} />
                      <MtpButton
                        expand='block'
                        color='mtp-red'
                        fill='outline'
                        onClick={toggleDeleteAccountModal}>
                        {utils.getTranslation(language, translator.pages.dashboard.deleteAccount)}
                      </MtpButton>
                      <MtpButton
                        type='submit'
                        expand='block'
                        disabled={!(props.touched.name || props.touched.firstName || props.touched.birthDate || props.touched.gender)}>
                        {utils.getTranslation(language, translator.pages.dashboard.saveChanges)}
                      </MtpButton>
                      <div className="mtp-dashboard-strava-wrapper">
                        {/* <img className="mtp-dashboard-strava-logo" src="./assets/strava-logo.svg" /> */}
                        {user.stravaAthleteId
                          ? <MtpButton
                            type='button'
                            expand='block'
                            color='strava'
                            onClick={dissasociateStrava}>
                            {utils.getTranslation(language, translator.pages.dashboard.disassociateStrava)}
                            <img className="mtp-strava-text-logo" src="./assets/strava-logo-light.svg" /></MtpButton>
                          : <MtpButton
                            type='button'
                            expand='block'
                            color='strava'
                            onClick={toggleStravaAssociationModal}>{utils.getTranslation(language, translator.pages.dashboard.associateStrava)}
                            <img className="mtp-strava-text-logo" src="./assets/strava-logo-light.svg" />
                          </MtpButton>
                        }
                      </div>
                      {
                        /**
                         * Render the Delete Account modal
                         */
                      }
                      <CustomModal
                        title={utils.getTranslation(language, translator.pages.dashboard.modals.deleteAccount.title)}
                        show={showDeleteAccountModal}
                        toggle={toggleDeleteAccountModal}>
                        <p>
                          {
                            /** Modal message different if user is an admin */
                            role === FamilyRoles.ADMIN
                              ? utils.getTranslation(language, translator.pages.dashboard.modals.deleteAccount.abstract.admin)
                              : utils.getTranslation(language, translator.pages.dashboard.modals.deleteAccount.abstract.default)
                          }
                        </p>
                        <div className="dashboard__btn-wrapper">
                          <MtpButton onClick={toggleDeleteAccountModal}>
                            {utils.getTranslation(language, translator.pages.family.modals.cancel)}
                          </MtpButton>
                          <MtpButton color="mtp-red" onClick={deleteAccount}>
                            {utils.getTranslation(language, translator.pages.family.modals.confirm)}
                          </MtpButton>
                        </div>
                      </CustomModal>
                      {
                        /**
                         * Render the Modify Email modal
                         */
                      }
                      <CustomModal
                        title={utils.getTranslation(language, translator.pages.dashboard.modals.modifyEmail.title)}
                        show={showEmailModal}
                        toggle={toggleEmailModal}>
                        <Formik
                          initialValues={{
                            newEmail: user?.email,
                          }}
                          validationSchema={yup.object({
                            newEmail: yup.string()
                              .email(utils.getTranslation(language, translator.formMessages.invalidEmail))
                              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
                          })}
                          onSubmit={(val, { setSubmitting, setTouched }) => {
                            updateUserEmail(val);
                            setShowEmailModal(false);
                            setTouched({});
                            setSubmitting(false);
                          }}>
                          <Form>
                            <Field
                              component={TextField}
                              name="newEmail"
                              label={utils.getTranslation(language, translator.pages.dashboard.modals.modifyEmail.newEmail)}
                              fullWidth
                              margin="normal" />
                            <Spacer height={20} />
                            <div className="dashboard__btn-wrapper">
                              <MtpButton color="mtp-red" onClick={() => toggleEmailModal()}>
                                {utils.getTranslation(language, translator.pages.dashboard.modals.modifyEmail.cancel)}
                              </MtpButton>
                              <MtpButton type="submit">
                                {utils.getTranslation(language, translator.pages.dashboard.modals.modifyEmail.submit)}
                              </MtpButton>
                            </div>
                          </Form>
                        </Formik>
                      </CustomModal>
                      {
                        /**
                         * Render the Modify Password modal
                         */
                      }
                      <CustomModal
                        title={utils.getTranslation(language, translator.pages.dashboard.modals.resetPassword.title)}
                        show={showPasswordModal}
                        toggle={togglePasswordModal}
                      >
                        <Formik
                          initialValues={{
                            newPassword: '',
                            confirmationPassword: '',
                          }}
                          validationSchema={yup.object({
                            newPassword: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
                            confirmationPassword: yup.string()
                              .required(utils.getTranslation(language, translator.formMessages.requiredField))
                              .oneOf([yup.ref('newPassword'), null], utils.getTranslation(language, translator.formMessages.passwordsMustMatch)),
                          })}
                          onSubmit={(val, { setSubmitting }) => {
                            setBusy(true);
                            setShowPasswordModal(false);
                            resetPassword(val);
                            setSubmitting(false);
                            setBusy(false);
                          }}
                        >
                          <Form>
                            <Field
                              component={TextField}
                              name="newPassword"
                              margin='normal'
                              type={showPassword ? 'text' : 'password'}
                              label={utils.getTranslation(language, translator.models.user.password)}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={(e: any) => e.preventDefault()}
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Field
                              component={TextField}
                              name="confirmationPassword"
                              margin='normal'
                              type={showPassword ? 'text' : 'password'}
                              label={utils.getTranslation(language, translator.pages.authPages.register.createAccount.confirmationPassword)}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={(e: any) => e.preventDefault()}
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Spacer height={15} />
                            <div className="dashboard__btn-wrapper">
                              <MtpButton color="mtp-red" onClick={() => togglePasswordModal()}>
                                {utils.getTranslation(language, translator.pages.dashboard.modals.resetPassword.cancel)}
                              </MtpButton>
                              <MtpButton type="submit">
                                {utils.getTranslation(language, translator.pages.dashboard.modals.resetPassword.submit)}
                              </MtpButton>
                            </div>
                          </Form>
                        </Formik>
                      </CustomModal>
                      <CustomModal
                        title={utils.getTranslation(language, translator.pages.dashboard.modals.changeAvatar.title)}
                        show={showAvatarModal}
                        toggle={toggleAvatarModal}>
                        <div className="dashboard__modal-avatar-wrapper">
                          {Avatars.map((url, key) => <div
                            key={key}
                            className={`mtp-avatar-wrapper ${(newAvatarPath === url) && 'selected'}`}
                            onClick={() => setNewAvatarPath(url)}
                          >
                            <img src={`./${url}`} alt="avatar" />
                          </div>)}
                        </div>
                        <div className="dashboard__btn-wrapper">
                          <MtpButton color="mtp-red" onClick={() => toggleAvatarModal()}>
                            {utils.getTranslation(language, translator.pages.dashboard.modals.changeAvatar.cancel)}
                          </MtpButton>
                          <MtpButton onClick={() => updateUserAvatar()} disabled={newAvatarPath === user?.avatarPath}>
                            {utils.getTranslation(language, translator.pages.dashboard.modals.changeAvatar.submit)}
                          </MtpButton>
                        </div>
                      </CustomModal>
                    </div>
                  </Form>
                )
              }
            </Formik>
            <CustomModal
              title={utils.getTranslation(language, translator.pages.dashboard.modals.accountCreated.title)}
              show={showAccountCreatedModal}
              toggle={toggleAccountCreatedModal}>
              <p>{utils.getTranslation(language, translator.pages.dashboard.modals.accountCreated.confirmCTA)}</p>
              <Spacer height={20} />
              <p>{utils.getTranslation(language, translator.pages.dashboard.modals.accountCreated.downloadCTA)}</p>
            </CustomModal>
            <CustomModal
              title={utils.getTranslation(language, translator.pages.dashboard.modals.associateStrava.title)}
              show={showStravaAssociationModal}
              toggle={toggleStravaAssociationModal}>
              <p>{utils.getTranslation(language, translator.pages.dashboard.modals.associateStrava.intro)}</p>
              <p><i><strong>{utils.getTranslation(language, translator.pages.dashboard.modals.associateStrava.disclaimer)}</strong></i></p>
              <Spacer height={20} />
              <div className="dashboard__btn-wrapper">
                <MtpButton color="mtp-red" onClick={toggleStravaAssociationModal}>
                  {utils.getTranslation(language, translator.pages.dashboard.modals.associateStrava.cancel)}</MtpButton>
                {family && family.plan !== PricingPlans.FREE && (
                  <MtpButton onClick={() => {
                    window.location.href = 'https://www.strava.com/oauth/authorize'
                      + `?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}`
                      + '&response_type=code'
                      + `&redirect_uri=${process.env.REACT_APP_PUBLIC_URL}/dashboard?from=associateStrava`
                      + '&approval_prompt=force'
                      + '&scope=read%2Cactivity%3Aread_all';
                  }}>{utils.getTranslation(language, translator.pages.dashboard.modals.associateStrava.confirm)}</MtpButton>
                )}
              </div>
            </CustomModal>
            <CustomModal
              title={utils.getTranslation(language, translator.pages.dashboard.modals.confirmStrava.title)}
              show={showStravaConfirmationModal}
              toggle={toggleStravaConfirmationModal}>
              {
                stravaAthlete === null
                  ? <p>...</p>
                  : <>
                    <p>{utils.getTranslation(language, translator.pages.dashboard.modals.confirmStrava.intro)}</p>
                    <div className="mtp-strava-info">
                      {stravaAthlete.profileURL && <img src={stravaAthlete.profileURL} alt={`profile pic of ${stravaAthlete.name}`} />}
                      <h2>{stravaAthlete.name}</h2>
                      {stravaAthlete.location && <p>{stravaAthlete.location}</p>}
                    </div>
                    <div className="dashboard__btn-wrapper">
                      <MtpButton color="mtp-red" onClick={handleCancelStravaAssociation}>
                        {utils.getTranslation(language, translator.pages.dashboard.modals.confirmStrava.cancel)}</MtpButton>
                      <MtpButton onClick={handleConfirmStravaAssociation}>
                        {utils.getTranslation(language, translator.pages.dashboard.modals.confirmStrava.confirm)}</MtpButton>
                    </div>
                  </>
              }
            </CustomModal>
            <PromptForCredentials show={showReauthenticateModal} toggle={toggleReauthenticateModal} />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Dashboard;
