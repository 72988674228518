import { Container } from '@mui/material';
import { useAppSelector } from '../../store/Hooks';
import CustomAlert from '../customAlert/CustomAlert';

import './AlertsManager.css';

const AlertMessages: React.FC = () => {
  const alertQueue = useAppSelector((state) => state.alerts.queue) as any[];

  return <Container
    className='mtp-alerts-manager'
    sx={{
      width: '100%',
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '10px',
      zIndex: 1001,
    }}>
    {
      alertQueue?.map((alert, key) => <div
        key={key}
        style={{
          minWidth: '325px',
        }}>
        <CustomAlert alert={alert} />
      </div>)
    }
    <div style={{
      position: 'fixed', top: '50%', left: '50%', zIndex: 1000,
    }}>
    </div>
  </Container>;
};

export default AlertMessages;
