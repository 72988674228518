import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { TextField } from 'formik-mui';
import * as Sentry from '@sentry/react';
import Spinner from '../../../component/spinner/Spinner';
import Sidebar from '../../../component/sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import User from '../../../model/User';
import MtpButton from '../../../component/mtpButton/mtpButton';
import './ForgotPassword.css';
import CustomModal from '../../../component/customModal/CustomModal';
import AnimatedCheckmark from '../../../component/animatedCheckmark/animatedCheckmark';
import utils from '../../../services/Utils';
import translator from '../../../assets/translator.json';
import emailManager from '../../../services/EmailManager';
import Spacer from '../../../component/spacer/Spacer';
import alertUtils from '../../../services/AlertUtils';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user) as User;
  const language = useAppSelector((state) => state.user.language);

  const [busy, setBusy] = useState(false);
  const [showEmailSentModal, setShowEmailSentModal] = useState(false);

  useEffect(() => {
    if (user) navigate('/dashboard');
  }, [user]);

  const toggleEmailSentModal = () => {
    setShowEmailSentModal(!showEmailSentModal);
  };

  const recoverPassword = async (values: any) => {
    try {
      setBusy(true);
      await emailManager.sendPasswordResetEmail(values.email);
      setBusy(false);
      toggleEmailSentModal();
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'ForgotPassword', action: 'recoverPassword', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotRecoverPassword), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div id="recover-password-page" className="content no-menu">
      <Sidebar />
      <Spinner show={busy} />
      <div className="main-content">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={yup.object({
            email: yup.string()
              .email(utils.getTranslation(language, translator.formMessages.invalidEmail))
              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
          })}
          onSubmit={(values, { setSubmitting }) => {
            recoverPassword(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <div className="mtp-form-container">
              <h1>{utils.getTranslation(language, translator.pages.authPages.forgotPassword.title)}</h1>
              <div className="mtp-input-wrapper">
                <Field component={TextField} name="email" label={utils.getTranslation(language, translator.models.user.email)} margin="normal" fullWidth />
                <Spacer height={30} />
                <MtpButton type="submit" expand="block">{utils.getTranslation(language, translator.pages.authPages.forgotPassword.submit)}</MtpButton>
              </div>
              <Link to={'/login'}>
                <div>{utils.getTranslation(language, translator.pages.authPages.forgotPassword.toLogin)}</div>
              </Link>
            </div>
          </Form>
        </Formik>
        <CustomModal
          show={showEmailSentModal}
          toggle={toggleEmailSentModal}
          title={utils.getTranslation(language, translator.pages.authPages.forgotPassword.emailSent)}
        >
          <AnimatedCheckmark scale={3} />
          <Spacer height={60}></Spacer>
        </CustomModal>
      </div>
    </div>
  );
};
export default ForgotPassword;
