import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import Login from '../pages/authPages/login/Login';
import Dashboard from '../pages/dashboard/Dashboard';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import Register from '../pages/authPages/register/Register';
import User from '../model/User';
import FamilyPage from '../pages/family/Family';
import NotFound from '../pages/notFound/NotFound';
import ForgotPassword from '../pages/authPages/forgotPassword/ForgotPassword';
import userManager from '../services/UserManager';
import alertUtils from '../services/AlertUtils';
import utils from '../services/Utils';
import translator from '../assets/translator.json';

import Devices from '../pages/devices/Devices';
import ChoosePlan from '../pages/choosePlan/ChoosePlan';
import BuyPowerplug from '../pages/buyPowerplug/BuyPowerplug';
// import Subscription from '../pages/subscription/Subscription';

const MainRouter: React.FC = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user) as User | null;
  const firebaseUser = useAppSelector((state) => state.user.firebaseUser) as any;
  const language = useAppSelector((state) => state.user.language);
  const dispatch = useAppDispatch();

  useEffect(() => {
    checkIfSuperAdmin();
  }, [location.pathname]);

  /** Redirect out of dashboard if user isn't superadmin */
  const checkIfSuperAdmin = async () => {
    try {
      if (!process.env.REACT_APP_REQUIRE_SUPERADMIN
        || location.pathname.startsWith('/register')
        || location.pathname === '/login'
        || location.pathname === '/forgot-password') return;

      userManager.checkIfSuperAdmin().then((isSuperAdmin: boolean) => {
        if (!isSuperAdmin) {
          alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.general.superAdminRequired), dispatch);
          // userManager.logout(dispatch);
        }
      });
    } catch (error: any) {
      console.error(error);
      const details = { component: 'MainRouter', action: 'checkIfSuperAdmin', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  /** Redirection components */
  const RequireUser = ({ children }: { children: React.ReactElement }) => (firebaseUser && user
    ? children
    : <Navigate to="/login" state={{ from: location }} />);

  return (
    <Routes>
      <Route path="*" element={
        <NotFound />
      } />
      <Route path="/login" element={
        <Login />
      } />
      <Route path="/forgot-password" element={
        <ForgotPassword />
      } />
      <Route path="/register" element={
        <Register />
      } />
      <Route path="/dashboard" element={
        <RequireUser>
          <Dashboard />
        </RequireUser>
      } />
      <Route path="/devices" element={
        <RequireUser>
          <Devices />
        </RequireUser>
      } />
      {/* <Route path="/subscription" element={
        <RequireUser>
          <Subscription />
        </RequireUser>
      } /> */}
      <Route path="/family" element={
        <RequireUser>
          <FamilyPage />
        </RequireUser>
      } />
      <Route path="/choose-plan" element={
        <RequireUser>
          <ChoosePlan />
        </RequireUser>
      } />
      <Route path="/buy-powerplug" element={
        <RequireUser>
          <BuyPowerplug />
        </RequireUser>
      } />
      <Route path="/" element={
        user && firebaseUser
          ? <Navigate to="/dashboard" state={{ from: location }} />
          : <Navigate to="/login" state={{ from: location }} />
      } />
    </Routes>
  );
};

export default MainRouter;
