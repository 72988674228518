import AvailableLanguages from '../model/enums/AvailableLanguages';
import alertUtils from './AlertUtils';
import utils from './Utils';
import translator from '../assets/translator.json';
import { functions } from '../FirebaseConfig';
import ErrorDetails from '../model/ErrorDetails';
import Timestamp from '../model/Timestamp';

class ErrorManager {
  currentLanguage: AvailableLanguages = AvailableLanguages.DEFAULT;

  /**
   * Entry point for error handling.
   *
   * @param error The error that was caught.
   * @param details The error details.
   * @param language The user's language at the time of catching.
   * @param dispatch The dispatch hook to send the toast.
   * @param userId The `userId` of the User that caused the error.
   */
  handleError = async (error: any, details: ErrorDetails, language: AvailableLanguages, dispatch: any, userId?: string) => {
    if (language && this.currentLanguage !== language) this.currentLanguage = language;

    if (error.code) {
      await this.handleFirebaseError(error, dispatch);
    } else {
      await alertUtils.createErrorAlert(utils.getTranslation(this.currentLanguage, translator.errorMessages.general.unknown), dispatch);
    }

    if (userId !== undefined) this.logError(userId, error, details);
  };

  /**
   * Displays the appropriate error alert from a Firebase Error.
   *
   * @param {any} error
   * @param {any} dispatch
   */
  private handleFirebaseError = async (error: any, dispatch: any) => {
    switch (error.code) {
      case 'auth/wrong-password':
      case 'auth/user-not-found': {
        const message = utils.getTranslation(this.currentLanguage, translator.errorMessages.firebase.auth.userNotFound);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      case 'auth/invalid-email': {
        const message = utils.getTranslation(this.currentLanguage, translator.errorMessages.general.invalidEmail);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      case 'auth/invalid-password': {
        const message = utils.getTranslation(this.currentLanguage, translator.errorMessages.general.invalidPassword);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      case 'already-exists': {
        let message;
        if (error.details?.requestName === 'family-updateFamily') message = utils.getTranslation(this.currentLanguage, translator.errorMessages.family.identifierAlreadyExists);
        else message = utils.getTranslation(this.currentLanguage, translator.errorMessages.general.unknown);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      case 'failed-precondition': {
        let message;
        if (error.details?.requestName === 'family-joinFamily') message = utils.getTranslation(this.currentLanguage, translator.errorMessages.family.needsUpgrade);
        else message = utils.getTranslation(this.currentLanguage, translator.errorMessages.general.unknown);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      case 'permission-denied': {
        const message = utils.getTranslation(this.currentLanguage, translator.errorMessages.general.permissionDenied);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      case 'not-found': {
        const message = utils.getTranslation(this.currentLanguage, translator.errorMessages.general.notFound);
        alertUtils.createErrorAlert(message, dispatch);
        break;
      }
      default: {
        alertUtils.createErrorAlert(utils.getTranslation(this.currentLanguage, translator.errorMessages.general.unknown), dispatch);
        break;
      }
    }
  };

  /**
   * Requests the API to log the specified error in Firestore.
   *
   * @param userId The `userId` of the User that caused the Error.
   * @param error The error caught by the view.
   */
  private logError = async (userId: string, error: any, details: ErrorDetails) => {
    const logErrorRef = functions.httpsCallable('error-logError');
    // eslint-disable-next-line consistent-return
    return logErrorRef({
      date: Timestamp.now(),
      userId,
      error: error.stack !== undefined
        ? error.stack
        : JSON.stringify(error),
      ...details,
    });
  };
}

const errorManager = new ErrorManager();
export default errorManager;
