/* eslint-disable import/no-cycle */
import { Stripe } from 'stripe';
import AvailableLanguages from '../model/enums/AvailableLanguages';
import translator from '../assets/translator.json';
import User from '../model/User';
import familyManager from './FamilyManager';
import stripeManager from './StripeManager';
import PricingPlans from '../model/enums/PricingPlans';
import cartManager from './CartManager';
import userManager from './UserManager';

class Utils {
  /**
   * Util function that calculate the first day of the week.
   *      - Used for getting the start date of the week
   * @condition
   *      - FirstDayOfWeek start time are sunday's at 4 am
   * @params Date
   * @return Date
  */
  firstDayOfWeek = (dateObject: Date) => new Promise<Date>((resolve) => {
    const date = dateObject;
    if (date.getDay() === 0) {
      const dateMin = new Date(date);
      dateMin.setHours(4, 0, 0, 0);
      if (date.getTime() >= dateMin.getTime()) {
        date.setHours(4, 0, 0, 0);
        resolve(date);
      } else {
        date.setDate(date.getDate() - 7);
        date.setHours(4, 0, 0, 0);
        resolve(date);
      }
    }
    date.setDate(date.getDate() - (date.getDay() || 7));
    date.setHours(4, 0, 0, 0);
    resolve(date);
  });

  /**
   * Get the Display value of a field on current language
   *
   * @param {string} language
   * @param {any} field
   * @returns {string} the display value
   */
  getTranslation = (language: string, field: any): string => {
    let lang = language;
    let translation = '';
    if (!lang) lang = AvailableLanguages.DEFAULT;
    translation = field[lang];
    return translation;
  };

  /**
   * Get the Display value of an enum translated to current language
   *
   * @param {string} language
   * @param {string} values the enum in camelCase
   * @param {string} value the value in the enum
   *
   * @returns {string | boolean} the translated value or false if value was not found in translator
   */
  getTranslatedEnumValue = (language: string, values: string, value: string): string | boolean => {
    // Get all the enums
    const enums = Object.entries(translator.models.enums);

    // Find the corresponding enum object
    let enumObject: any[] = [];
    enums.forEach((enumObjectTemp: any[]) => {
      if (enumObjectTemp[0] === values) enumObject = Object.entries(enumObjectTemp[1]);
    });
    if (!enumObject.length) return false;

    // Find the corresponding value
    let valueObject;
    enumObject.forEach((valueObjectTemp: any[]) => {
      if (valueObjectTemp[0] === value) valueObject = { ...valueObjectTemp[1] };
    });
    if (!valueObject) return false;

    // Call the translation utility
    return this.getTranslation(language, valueObject);
  };

  /**
   * Creates an array of options from an enum
   *
   * @param {enum} values
   * @returns {Array<{ string, string }>} an array of options
   */
  optionsFromEnum = (values: any) => {
    const options: any[] = [];
    (Object.keys(values) as (keyof typeof values)[]).map(
      (key, index) => options.push(
        { value: values[key], label: values[key] },
      ),
    );
    return options;
  };

  /**
   * Formats a date to the `DD-MM-YYYY` format.
   *
   * @param date The received date.
   * @returns The date in the `DD-MM-YYYY` format.
   */
  formatDate = (date: Date): string => [
    `0${date.getMonth() + 1}`.slice(-2),
    `0${date.getDate()}`.slice(-2),
    date.getFullYear(),
  ].join('-');

  /**
   * Handles verification of the user's active Stripe Checkout Session.
   * Applies products to the user's Family depending on what has been purchased. If the session status is not open,
   * the cart will be cleared after handling.
   *
   * @param {User} user
   */
  verifyCheckoutSession = async (user: User) => {
    if (!user.stripeCustomer) return;
    if (!user.stripeCustomer.activeCheckoutSessionId) return;

    const family = await familyManager.getFamilyById(user.familyId);
    if (!family) throw new Error('User has no Family!');

    const checkoutSession: Stripe.Checkout.Session = await stripeManager.retrieveUserActiveCheckoutSession(user.userId);
    if (checkoutSession.status === 'open') return;

    const activeSubscriptionId = checkoutSession.subscription
      ? checkoutSession.subscription
      : user.stripeCustomer.activeSubscriptionId;

    if (checkoutSession.status === 'complete' && checkoutSession.payment_status === 'paid') {
      const purchasedProducts = await stripeManager.retrieveUserActiveCheckoutSessionLineItems(user.userId) as Stripe.LineItem[];
      if (!purchasedProducts) return;

      // apply the corresponding plan
      const stripePlans = await stripeManager.listStripePlans() as Stripe.Product[];
      const engagedPlan = stripePlans.find((product: Stripe.Product) => product.metadata.plan === PricingPlans.PREMIUM) as Stripe.Product;
      const engagedItem = purchasedProducts.find((product: Stripe.LineItem) => product.price?.product === engagedPlan.id) as Stripe.LineItem;
      if (engagedPlan && engagedItem) {
        await familyManager.updateFamily(family.id, { plan: PricingPlans.PREMIUM });
      }
    }

    cartManager.clearCart();
    await userManager.updateUserData(user.userId, { stripeCustomer: { activeCheckoutSessionId: '', activeSubscriptionId } });
  };
}

const utils = new Utils();
export default utils;
