import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import MtpButton from '../../component/mtpButton/mtpButton';
import Sidebar from '../../component/sidebar/Sidebar';
import Spinner from '../../component/spinner/Spinner';
import User from '../../model/User';
import { useAppSelector } from '../../store/Hooks';
import './NotFound.css';
import utils from '../../services/Utils';
import translator from '../../assets/translator.json';

const NotFound: React.FC = () => {
  const [busy, setBusy] = useState(true);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user) as unknown as User;
  const language = useAppSelector((state) => state.user.language);

  const redirect = () => {
    if (user) navigate(-1);
    else navigate('/login');
  };

  useEffect(() => {
    setBusy(false);
  }, []);

  return (
    <div className="content no-menu">
      <Sidebar />
      <Spinner show={busy} />
      <div className="main-content">
        <div className="not-found__wrapper">
          <h1>{utils.getTranslation(language, translator.pages.notFound.title)}</h1>
          <p>{utils.getTranslation(language, translator.pages.notFound.abstract)}</p>
          <MtpButton onClick={() => redirect()}>{utils.getTranslation(language, translator.pages.notFound.toPreviousPage)}</MtpButton>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
