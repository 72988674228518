import { functions } from '../FirebaseConfig';
import Family from '../model/Family';
import User from '../model/User';

class PowerplugManager {
  createPowerplug = async (family: Family, user: User, name: any) => {
    const createPowerplug = functions.httpsCallable('powerplug-createPowerplug');
    const response = await createPowerplug({ name, userId: user.userId, familyId: family.id });
    return response;
  };

  deletePowerplug = async (id: string) => {
    const deletePowerplugRef = functions.httpsCallable('powerplug-deletePowerplug');
    await deletePowerplugRef({ id });
  };

  updatePowerplug = async (id: string, data: any) => {
    const updatePowerplugRef = functions.httpsCallable('powerplug-updatePowerplug');
    await updatePowerplugRef({ id, ...data });
  };

  getPowerplugsByFamily = async (familyId: string) => {
    const getFamilyPowerplugsRef = functions.httpsCallable('powerplug-getFamilyPowerplugs');
    const response = await getFamilyPowerplugsRef({ familyId });
    return response.data;
  };
}

const powerplugManager = new PowerplugManager();
export default powerplugManager;
