/* eslint-disable no-prototype-builtins */
/* eslint-disable no-useless-catch */
import { Stripe } from 'stripe';
import Cart from '../model/Cart';
import CartItem from '../model/CartItem';

/** Manages tasks related to the Cart and LocalStorage */
class CartManager {
  private cart: Cart;

  getCart = () => this.cart;

  setCart = (newCart: Cart) => {
    this.cart = { ...newCart };
  };

  constructor() {
    const cartString = localStorage.getItem('cart');
    if (cartString) this.cart = JSON.parse(cartString);
    else this.cart = { items: [] as Array<CartItem> };
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  /** Loads cart from the LocalStorage */
  loadCart = () => {
    const cartString = localStorage.getItem('cart');
    if (cartString) this.cart = JSON.parse(cartString);
    else this.cart = { items: [] as Array<CartItem> };
  };

  /** Stores CartManager's cart data in the LocalStorage */
  storeCart = () => {
    localStorage.setItem('cart', JSON.stringify(this.cart));
  };

  /**
   * Adds a set quantity of Products to the cart, or increases quantity by set number if the item is already present.
   * Default value for quantity is 1.
   *
   * If the item is a plan and there is already a plan in the cart, the previous plan will be overwritten.
   *
   * @param {Stripe.Product} item
   * @param {number} quantity
   */
  addItem = (item: Stripe.Product, quantity = 1) => {
    try {
      if (quantity < 1) throw Error('Invalid quantity!');

      if (item.metadata.hasOwnProperty('plan')) {
        if (quantity !== 1) throw Error('Invalid quantity!');

        // Remove all other plans from cart, then push
        this.setCart({ items: this.cart.items.filter((cartItem: CartItem) => !cartItem.product.metadata?.hasOwnProperty('plan')) });
        this.cart.items.push({ product: item, quantity } as CartItem);
      } else {
        // Increase quantity if found or push to array
        const itemRef = this.cart.items.find((itemTemp) => itemTemp.product && itemTemp.product.id === item.id);
        if (itemRef) itemRef.quantity += quantity;
        else this.cart.items.push({ product: item, quantity } as CartItem);
      }

      this.storeCart();
    } catch (e: any) {
      throw e;
    }
  };

  /**
   * Removes a set quantity of Products from the cart, or removes the item from cart if set to all
   * Default value for quantity is 'all'.
   *
   * @param {Stripe.Product} item
   * @param {number | 'all'} quantity
   */
  removeItem = (item: Stripe.Product, quantity: number | 'all' = 'all') => {
    try {
      if (quantity < 1) throw Error('Invalid quantity!');

      const itemRef = this.cart.items.find((itemTemp) => itemTemp.product.id === item.id);
      if (!itemRef) throw Error('Item not found in cart!');

      if (quantity === 'all' || quantity >= itemRef.quantity) this.cart.items.splice(this.cart.items.indexOf(itemRef), 1);
      else itemRef.quantity -= quantity;

      this.storeCart();
    } catch (e: any) {
      throw e;
    }
  };

  /**
   * Updates the quantity of a Cart Item to set value
   *
   * @param {Stripe.Product} item
   * @param {number} quantity
   */
  updateItemQuantity = (item: Stripe.Product, quantity: number) => {
    try {
      if (quantity < 0) throw Error('Invalid quantity!');

      if (quantity === 0) {
        this.removeItem(item);
        return;
      }

      if (item.metadata && item.metadata.plan) throw Error('Can\'t update quantity of Plan!');

      const itemRef = this.cart.items.find((itemTemp) => itemTemp.product.id === item.id);
      if (!itemRef) throw Error('Item not found in cart!');

      itemRef.quantity = quantity;

      this.storeCart();
    } catch (e: any) {
      throw e;
    }
  };

  /** Clears the cart */
  clearCart = () => {
    this.cart = { items: [] as Array<CartItem> };
    this.storeCart();
  };
}

const cartManager = new CartManager();
export default cartManager;
