/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateItems(state, action: PayloadAction<any>) {
      state.items = action.payload.items;
    },
  },
});

export const {
  updateItems,
} = cartSlice.actions;

export default cartSlice;
