import { responsiveFontSizes } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';

// eslint-disable-next-line import/no-mutable-exports
let themeConfig = createTheme({
  palette: {
    mode: 'dark',
  },
  shape: {
    borderRadius: 8,
  },
});

themeConfig = responsiveFontSizes(themeConfig);

export default themeConfig;
