import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import * as Sentry from '@sentry/react';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import CustomModal from '../customModal/CustomModal';
import translator from '../../assets/translator.json';
import MtpButton from '../mtpButton/mtpButton';
import Spacer from '../spacer/Spacer';
import userManager from '../../services/UserManager';
import alertUtils from '../../services/AlertUtils';
import utils from '../../services/Utils';

interface ContainerProps {
  show: boolean,
  toggle: () => void,
}

const PromptForCredentials: React.FC<ContainerProps> = ({ show, toggle }) => {
  const language = useAppSelector((state) => state.user.language);
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const reauthenticate = async (val: { password: string }) => {
    try {
      await userManager.reauthenticate(val.password);
    } catch (error: any) {
      console.error(error);
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotReauthenticate), dispatch);
      const details = { component: 'PromptForCredentials', action: 'reauthenticate', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  return <CustomModal
    show={show}
    toggle={toggle}
    title={utils.getTranslation(language, translator.components.promptForCredentials.title)}>
    <p>{utils.getTranslation(language, translator.components.promptForCredentials.intro)}</p>
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={yup.object({
        password: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
      })}
      onSubmit={async (val, { setSubmitting }) => {
        await reauthenticate(val);
        setSubmitting(false);
        toggle();
      }}>
      <Form>
        <Field
          component={TextField}
          name="password"
          margin='normal'
          type={showPassword ? 'text' : 'password'}
          label={utils.getTranslation(language, translator.models.user.password)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={(e: any) => e.preventDefault()}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Spacer height={15} />
        <div className="dashboard__btn-wrapper">
          <MtpButton color="mtp-red" onClick={() => toggle()}>
            {utils.getTranslation(language, translator.components.promptForCredentials.cancel)}
          </MtpButton>
          <MtpButton type="submit">
            {utils.getTranslation(language, translator.components.promptForCredentials.submit)}
          </MtpButton>
        </div>
      </Form>
    </Formik>
  </CustomModal>;
};

export default PromptForCredentials;
