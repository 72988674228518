import React, { useEffect, useState } from 'react';
import './SelectInput.css';
import { FieldHookConfig, useField } from 'formik';
import ReactSelect from 'react-select';

interface ContainerProps {
  options: Array<string>,
  disabled?: boolean,
  label?: string;
  color?: string,
  styles?: any,
  customClass?: string
}

const SelectInput: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  label,
  disabled,
  customClass,
  color,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [state, setState] = useState('');
  useEffect(() => {
    if (meta.error && meta.touched) setState('danger');
    else setState('');
  }, [meta.error]);

  const changeSelectValue = (val: string) => {
    helpers.setValue(val);
  };

  return (
    <div style={{ ...props.style, position: 'relative' }} className={`mtp-field-wrapper ${customClass || ''} ${disabled ? 'disabled' : ''}`} >
      <div
        style={{ borderColor: `var(--${state})` }}
        className="mtp-select-input-wrapper"
      >
        {
          label
          && <label htmlFor={props.id}
            style={{ color: `${disabled ? 'var(--grey)' : `var(--${state || 'light'}`})` }}
            className="mtp-label">{label}</label>
        }
        <ReactSelect
          {...field}
          id={props.id}
          className="mtp-select-input"
          classNamePrefix="mtp-select-input"
          options={options}
          value={options.find((option: any) => option.value === field.value)}
          onBlur={field.onBlur}
          onChange={(e: any) => changeSelectValue(e.value)}
          isSearchable={false}
          isDisabled={disabled}
        ></ReactSelect>
      </div>
      <div className="mtp-form-msg-wrapper">
        <p className='mtp-form-msg'>{meta.error && meta.touched ? meta.error : ''}</p>
      </div>
    </div >
  );
};

SelectInput.defaultProps = {
  color: 'light',
  disabled: false,
};

export default SelectInput;
