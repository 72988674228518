import Stripe from 'stripe';
import { functions } from '../FirebaseConfig';
import BillingInformation from '../model/BillingInformation';
import Item from '../model/Item';

class StripeManager {
  private publicUrl = process.env.REACT_APP_PUBLIC_URL;

  /**
   * Request the API to create a Stripe Customer.
   *
   * @param {string} userId
   * @returns {Promise<Stripe.Customer>} The newly created customer
   */
  createCustomer = async (userId: string): Promise<Stripe.Customer> => {
    const createStripeCustomer = functions.httpsCallable('stripe-createStripeCustomer');
    const response = await createStripeCustomer({ userId });
    return response.data as Stripe.Customer;
  };

  /**
   * Request the API to create a Stripe Customer session
   * @param {string} userId
   * @param {Item[]} items
   * @param {'payment | 'subscription'} mode Use subscription if the items include a subscription & products
   * @param {string} successPath
   * @param {string} cancelPath
   * @returns {Stripe.Checkout.Session} a Stripe Checkout Session object
   */
  createCheckoutSession = async (userId: string, items: Item[], mode: 'payment' | 'subscription', successPath: string, cancelPath: string) => {
    const successUrl = this.publicUrl + successPath;
    const cancelUrl = this.publicUrl + cancelPath;

    const createCustomerCheckoutSession = functions.httpsCallable('stripe-createStripeCheckout');
    const response = await createCustomerCheckoutSession({
      userId, items, mode, successUrl, cancelUrl,
    });
    return response.data;
  };

  /**
   * Request the API to expire the specified user's active checkout session
   *
   * @param {User} userId
   * @returns {Stripe.Checkout.Session}
   */
  expireUserActiveCheckoutSession = async (userId: string) => {
    const expireActiveUserCheckoutSession = functions.httpsCallable('stripe-expireUserActiveStripeCheckout');
    const response = await expireActiveUserCheckoutSession({ userId });
    return response.data;
  };

  /**
   * Request the API to retrieve the specified user's active checkout session
   *
   * @param {string} userId
   * @returns {Stripe.Checkout.Session}
   */
  retrieveUserActiveCheckoutSession = async (userId: string) => {
    const retrieveActiveUserCheckoutSession = functions.httpsCallable('stripe-retrieveUserActiveStripeCheckout');
    const response = await retrieveActiveUserCheckoutSession({ userId });
    return response.data;
  };

  /**
   * Request the API to retrieve the specified user's active checkout session's list items
   *
   * @param {string} userId
   * @returns {Stripe.LineItem[]}
   */
  retrieveUserActiveCheckoutSessionLineItems = async (userId: string) => {
    const retrieveUserActiveCheckoutSessionLineItems = functions.httpsCallable('stripe-retrieveUserActiveStripeCheckoutLineItems');
    const response = await retrieveUserActiveCheckoutSessionLineItems({ userId });
    return response.data.data;
  };

  /**
   * Request the API to cancel the specified user's active subscription
   *
   * @param {string} userId
   * @returns {Stripe.Subscription}
   */
  cancelUserActiveSubscription = async (userId: string) => {
    const cancelUserActiveStripeSubscription = functions.httpsCallable('stripe-cancelUserActiveStripeSubscription');
    const response = await cancelUserActiveStripeSubscription({ userId });
    return response.data;
  };

  /**
   * Request the API to list all Stripe products
   *
   * @param {boolean} includeInactive Wether to include inactive products to the query, defaults to false.
   * @returns {Stripe.Product[]}
   */
  listStripeProducts = async (includeInactive = false) => {
    const listStripeProducts = functions.httpsCallable('stripe-listStripeProducts');
    const response = await listStripeProducts();

    if (includeInactive) return response.data.data;
    return response.data.data.filter((product: Stripe.Product) => product.active);
  };

  /**
   * Request the API to list all active Stripe plans
   *
   * @returns {Stripe.Product[]}
   */
  listStripePlans = async () => {
    const stripeProducts = await this.listStripeProducts();
    const stripePlans = stripeProducts.filter((product: Stripe.Product) => product.metadata.plan);
    return stripePlans;
  };

  /**
   * Request the API to list all active Stripe devices
   * @returns {Stripe.Product[]}
   */
  listStripeDevices = async () => {
    const stripeProducts = await this.listStripeProducts();
    const stripeDevices = stripeProducts.filter((product: Stripe.Product) => product.metadata.device);
    return stripeDevices;
  };

  /**
   * Request the API to retrieve a Price Object by ID
   *
   * @param {string} id
   * @returns {Stripe.Price}
   */
  retrieveStripePrice = async (id: string) => {
    const retrieveStripePriceRef = functions.httpsCallable('stripe-retrieveStripePrice');
    const response = await retrieveStripePriceRef({ id });
    return response.data;
  };

  /**
   * Requests the API to retrieve the list of active Stripe Subscriptions of the current User.
   *
   * @returns The retrieved Stripe Subscriptions.
   */
  listStripeSubscriptions = async (): Promise<Stripe.Subscription[]> => {
    const listStripeSubscriptions = functions.httpsCallable('stripe-listStripeActiveSubscriptions');
    const response = await listStripeSubscriptions();
    return response.data;
  };

  /**
   * Requests the API to delete the Stripe Subscription with the specified id.
   *
   * @param subscriptionId The id of the Stripe Subscription to delete.
   */
  cancelStripeSubscription = async (subscriptionId: string): Promise<void> => {
    const cancelStripeSubscriptionRef = functions.httpsCallable('stripe-cancelStripeSubscription');
    await cancelStripeSubscriptionRef({ subscriptionId });
  };

  /**
   * Requests the API to retrieve the User's Stripe billing information. The information contains the
   * Customer's name and its billing address.
   *
   * @param userId The `userId` of the User from which the customer's billing information will be retrieved.
   *
   * @returns A promise that resolves into retrieving the BillingInformation, or `null` if it has not been set.
   */
  retrieveUserBillingInfo = async (userId: string): Promise<BillingInformation | null> => {
    const retrieveBillingInfoRef = functions.httpsCallable('stripe-retrieveUserBillingInfo');
    const response = await retrieveBillingInfoRef({ userId });
    return response.data;
  };

  /**
   * Requests the API to create a Stripe Customer Portal for the specified User.
   *
   * @param returnPath The path when the session is closed.
   *
   * @returns A promise that resolves into retrieving a Stripe Customer Portal URL.
   */
  createCustomerPortal = async (returnPath: string): Promise<string> => {
    const returnUrl = this.publicUrl + returnPath;

    const createCustomerPortalRef = functions.httpsCallable('stripe-createCustomerPortal');
    const response = await createCustomerPortalRef({ returnUrl });
    return response.data;
  };
}

const stripeManager = new StripeManager();

export default stripeManager;
