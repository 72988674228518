import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  FaCheckCircle,
} from 'react-icons/fa';
import Stripe from 'stripe';
import { useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ReactComponent as Exclude } from '../../assets/img/circle-xmark-solid.svg';
import User from '../../model/User';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import './ChoosePlan.css';
import Spinner from '../../component/spinner/Spinner';
import PricingPlans from '../../model/enums/PricingPlans';
import Sidebar from '../../component/sidebar/Sidebar';
import MtpButton from '../../component/mtpButton/mtpButton';
import DraggableSlider from '../../component/draggableSlider/DraggableSlider';
import utils from '../../services/Utils';
import translator from '../../assets/translator.json';
import stripeManager from '../../services/StripeManager';
import cartManager from '../../services/CartManager';
import CustomModal from '../../component/customModal/CustomModal';
import Family from '../../model/Family';
import FamilyRoles from '../../model/enums/FamilyRoles';
import familyManager from '../../services/FamilyManager';
import alertUtils from '../../services/AlertUtils';

const ChoosePlan: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as User | null;
  const family = useAppSelector((state) => state.user.family) as Family | null;
  const language = useAppSelector((state) => state.user.language);
  const role = useAppSelector((state) => state.user.role);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [busy, setBusy] = useState(false);
  const [showConfirmDowngradeModal, setShowConfirmDowngradeModal] = useState(false);
  const [activeSlide0, setActiveSlide0] = useState('active');
  const [activeSlide1, setActiveSlide1] = useState('');

  const toggleConfirmDowngradeModal = () => setShowConfirmDowngradeModal((prev) => !prev);

  useEffect(() => {
    if (!role) return;
    if (role !== FamilyRoles.ADMIN) navigate('/dashboard');
    const fromParam = searchParams.get('from');
    if (fromParam === 'checkoutCancelled') {
      cartManager.clearCart();
    }
  }, [role]);

  const activeSlideToggler = (slide: number) => {
    setActiveSlide0(slide === 0 ? 'active' : '');
    setActiveSlide1(slide === 1 ? 'active' : '');
  };

  const chooseUserPlan = async (plan: PricingPlans) => {
    try {
      if (!family || !user) return;
      if (plan === family.plan) {
        navigate('/subscription');
        return;
      }
      setBusy(true);

      if (plan === PricingPlans.FREE) {
        await stripeManager.cancelUserActiveSubscription(user.userId);
        await familyManager.updateFamily(family.id, { plan: PricingPlans.FREE });
        navigate('/subscription');
        return;
      }

      const stripePlans = await stripeManager.listStripePlans();
      const engagedPlan = stripePlans.find((product: Stripe.Product) => product.metadata.plan === PricingPlans.PREMIUM);

      if (plan === PricingPlans.PREMIUM) {
        if (!engagedPlan) throw Error('Engaged plan Stripe Product not found!');
        cartManager.addItem(engagedPlan);
        navigate('/buy-powerplug');
        return;
      }

      throw new Error('Unexpected pricing plan!');
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'ChoosePlan', action: 'chooseUserPlan', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.tryAgain), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div className="content choose-plan-page" style={{ overflow: 'hidden' }}>
      <Sidebar isMenu={true} />
      <Spinner show={busy} />
      <div className="main-content">
        {
          /**
           * Render the Desktop Choose Plan container
           */
        }
        <div className="hide-mobile">
          <div className="choosePlan__container">
            <div></div>
            <div className="choosePlan__grid-item">
              <h1>{utils.getTranslation(language, translator.models.enums.pricingPlans.free)}</h1>
            </div>
            <div className="choosePlan__grid-item">
              <h1>{utils.getTranslation(language, translator.models.enums.pricingPlans.engaged)}</h1>
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.price.title)}</p>
            </div>
            <div className="choosePlan__grid-item item-center col-header">
              <div>
                <p className="choosePlan__grid-item-title">0$</p>
                <p>{utils.getTranslation(language, translator.pages.choosePlan.price.perMonth)}</p>
              </div>
            </div>
            <div className="choosePlan__grid-item item-center col-header">
              <div>
                <p className="choosePlan__grid-item-title">10$</p>
                <p>{utils.getTranslation(language, translator.pages.choosePlan.price.perMonth)}</p>
              </div>
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.membersPerFamily.title)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.membersPerFamily.free)}</p>
            </div>
            <div className="choosePlan__grid-item item-center ">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.membersPerFamily.unlimited)}</p>
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.manualEntries)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.stopwatches)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.consumptionReports)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.notifications)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.rulesObjectivesEditing)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <Exclude style={{ height: '20px', width: '20px', color: 'red' }} />
            </div>
            <div className="choosePlan__grid-item item-center">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.videoGamesTracking)}</p>
            </div>
            <div className="choosePlan__grid-item item-center">
              <Exclude style={{ height: '20px', width: '20px', color: 'red' }} />
            </div>
            <div className="choosePlan__grid-item item-center ">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__grid-item item-left">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.sportsTracking)}</p>
            </div>
            <div className="choosePlan__grid-item item-center col-footer">
              <Exclude style={{ height: '20px', width: '20px', color: 'red' }} />
            </div>
            <div className="choosePlan__grid-item item-center col-footer">
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div></div>
            <div className="choosePlan__grid-item item-center item-end">
              <MtpButton fill="outline" onClick={() => { chooseUserPlan(PricingPlans.FREE); }}>
                {family?.plan === PricingPlans.FREE
                  ? utils.getTranslation(language, translator.pages.choosePlan.keep)
                  : utils.getTranslation(language, translator.pages.choosePlan.choose)}
              </MtpButton>
            </div>
            <div className="choosePlan__grid-item item-center item-end">
              <MtpButton fill="outline" onClick={() => { chooseUserPlan(PricingPlans.PREMIUM); }}>
                {family?.plan === PricingPlans.PREMIUM
                  ? utils.getTranslation(language, translator.pages.choosePlan.keep)
                  : utils.getTranslation(language, translator.pages.choosePlan.choose)}
              </MtpButton>
            </div>
          </div>
        </div>
        {
          /**
           * Render the Mobile Choose Plan Container
           */
        }
        <div className="show-mobile">
          <DraggableSlider activeSlideToggler={activeSlideToggler}>
            <div className={`slide ${activeSlide0}`}>
              <div className="mtp-card">
                <h3>{utils.getTranslation(language, translator.models.enums.pricingPlans.free)}</h3>
                <div className="mtp-price">
                  <div className="mtp-big">0$</div> {utils.getTranslation(language, translator.pages.choosePlan.price.perMonth)}
                </div>
              </div>
            </div>
            <div className={`slide ${activeSlide1}`}>
              <div className="mtp-card">
                <h3>{utils.getTranslation(language, translator.models.enums.pricingPlans.engaged)}</h3>
                <p>Sans prise intelligente</p>
                <div className="mtp-price">
                  <div className="mtp-big">10$</div> {utils.getTranslation(language, translator.pages.choosePlan.price.perMonth)}
                </div>
              </div>
            </div>
          </DraggableSlider>
          <div className="choosePlan__details">
            <div className="choosePlan__details__item">
              <h4>{utils.getTranslation(language, translator.pages.choosePlan.membersPerFamily.title)}</h4>
              <p>
                {
                  activeSlide0 === 'active'
                    ? utils.getTranslation(language, translator.pages.choosePlan.membersPerFamily.free)
                    : utils.getTranslation(language, translator.pages.choosePlan.membersPerFamily.unlimited)
                }
              </p>
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.manualEntries)}</p>
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.stopwatches)}</p>
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.consumptionReports)}</p>
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.notifications)}</p>
              <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.rulesObjectivesEditing)}</p>
              {
                activeSlide0 === 'active'
                  ? <Exclude style={{ height: '20px', width: '20px', color: 'red' }} />
                  : <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
              }
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.videoGamesTracking)}</p>
              {
                activeSlide0 === 'active'
                  ? <Exclude style={{ height: '20px', width: '20px', color: 'red' }} />
                  : <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
              }
            </div>
            <div className="choosePlan__details__item">
              <p>{utils.getTranslation(language, translator.pages.choosePlan.sportsTracking)}</p>
              {
                activeSlide0 === 'active'
                  ? <Exclude style={{ height: '20px', width: '20px', color: 'red' }} />
                  : <FaCheckCircle style={{ height: '20px', width: '20px', color: 'var(--mtp-green)' }} />
              }
            </div>
          </div>
          {activeSlide0 === 'active'
            && <div className="choosePlan__button-mobile">
              <MtpButton fill="outline" expand="block" onClick={() => { chooseUserPlan(PricingPlans.FREE); }}>
                {family?.plan === PricingPlans.FREE
                  ? utils.getTranslation(language, translator.pages.choosePlan.keep)
                  : utils.getTranslation(language, translator.pages.choosePlan.choose)}
              </MtpButton>
            </div>}
          {activeSlide1 === 'active'
            && <div className="choosePlan__button-mobile">
              <MtpButton fill="outline" expand="block" onClick={() => { chooseUserPlan(PricingPlans.PREMIUM); }}>
                {family?.plan === PricingPlans.PREMIUM
                  ? utils.getTranslation(language, translator.pages.choosePlan.keep)
                  : utils.getTranslation(language, translator.pages.choosePlan.choose)}
              </MtpButton>
            </div>}
        </div>
      </div>
      <CustomModal
        title={utils.getTranslation(language, translator.pages.choosePlan.modals.confirmDowngrade.title)}
        show={showConfirmDowngradeModal}
        toggle={toggleConfirmDowngradeModal}>
        <p>{utils.getTranslation(language, translator.pages.choosePlan.modals.confirmDowngrade.intro)}</p>
        <div className="dashboard__btn-wrapper">
          <MtpButton color="mtp-red" onClick={() => toggleConfirmDowngradeModal()}>
            {utils.getTranslation(language, translator.pages.choosePlan.modals.confirmDowngrade.cancel)}
          </MtpButton>
          <MtpButton onClick={() => null}>
            {utils.getTranslation(language, translator.pages.choosePlan.modals.confirmDowngrade.confirm)}
          </MtpButton>
        </div>
      </CustomModal >
    </div >
  );
};

export default ChoosePlan;
