import { functions } from '../FirebaseConfig';
import StravaAthlete from '../model/StravaAthlete';

class StravaManager {
  /**
   * ...
   */
  authorizeUser = async (userId: string, code: string, scope: string): Promise<StravaAthlete> => {
    const authorizeUserRef = functions.httpsCallable('strava-authorizeUser');
    const response = await authorizeUserRef({ userId, code, scope });
    return response.data;
  };

  /**
   *
   */
  deauthorizeUser = async (userId: string) => {
    const deauthorizeUserRef = functions.httpsCallable('strava-deauthorizeUser');
    const response = await deauthorizeUserRef({ userId });
    return response.data;
  };

  /**
   *
   */
  deauthorizeAthlete = async (athleteId: number) => {
    const deauthorizeAthleteRef = functions.httpsCallable('strava-deauthorizeAthlete');
    return deauthorizeAthleteRef({ athleteId });
  };
}

const stravaManager = new StravaManager();
export default stravaManager;
