import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import MainRouter from './routing/MainRouter';
import './assets/css/index.css';
import './assets/css/variables.css';
import { auth, db } from './FirebaseConfig';
import userManager from './services/UserManager';
import { useAppDispatch } from './store/Hooks';
import {
  updateFamily, updateFirebaseUser, updateLanguage, updateRole, updateUser,
} from './store/slices/UserSlice';
import Background from './component/background/Background';
import familyManager from './services/FamilyManager';
import AvailableLanguages from './model/enums/AvailableLanguages';
import User from './model/User';
import themeConfig from './theme/ThemeConfig';
import AlertsManager from './component/alertsManager/AlertsManager';
import packageInfo from '../package.json';

Sentry.init({
  release: `mtp-web@${packageInfo.version ? packageInfo.version : 'default'}`,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_PROJECT_ID,
  tracesSampler: () => (process.env.NODE_ENV === 'production' ? 0.2 : 0),
  enabled: process.env.NODE_ENV !== 'development',
});

function App() {
  const theme = createTheme(themeConfig);
  const dispatch = useAppDispatch();

  const [busy, setBusy] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribe = () => { };
    try {
      auth.onAuthStateChanged(async (firebaseUser: any) => {
        if (firebaseUser) {
          unsubscribe = db.collection('Users').doc(firebaseUser.uid)
            .onSnapshot(async (doc: any) => {
              const userData: User = await doc.data();

              if (userData?.familyId) {
                const familyTemp = await familyManager.getFamilyById(userData.familyId);
                if (familyTemp) {
                  const role = await familyManager.getFamilyMemberRole(userData.userId, userData.familyId);
                  dispatch(updateFamily(familyTemp));
                  dispatch(updateRole(role));
                }
              }

              Sentry.setUser({
                id: userData.userId,
                email: userData.email,
                username: userData.name,
              });

              dispatch(updateLanguage(userData?.language || AvailableLanguages.DEFAULT));
              dispatch(updateUser(userData));
              dispatch(updateFirebaseUser(firebaseUser));
              setBusy(false);
            });
          userManager.pushSubscribers(unsubscribe);
        } else {
          dispatch(updateUser(null));
          dispatch(updateFirebaseUser(null));
          dispatch(updateLanguage(AvailableLanguages.DEFAULT));
          setBusy(false);
        }
      });
    } catch (error: any) {
      console.error(error);
      const details = { component: 'App', action: 'useEffect', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
    return unsubscribe;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Background />
        {
          !busy
          && (
            <BrowserRouter>
              <MainRouter />
            </BrowserRouter>
          )
        }
        <AlertsManager />
      </div>
    </ThemeProvider>
  );
}

export default App;
