import './Background.css';

const Background: React.FC = () => (
  <div className="mtp-background-wrapper">
    <div className="mtp-bubble-a"></div>
    <div className="mtp-bubble-b"></div>
  </div>
);

export default Background;
