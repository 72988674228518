/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Stripe } from 'stripe';
import { useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ReactComponent as CheckIcon } from '../../assets/img/check.svg';
import Powerplug from '../../assets/img/powerplug.png';
import './BuyPowerplug.css';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import User from '../../model/User';
import Spinner from '../../component/spinner/Spinner';
import Sidebar from '../../component/sidebar/Sidebar';
import MtpButton from '../../component/mtpButton/mtpButton';
import utils from '../../services/Utils';
import translator from '../../assets/translator.json';
import stripeManager from '../../services/StripeManager';
import cartManager from '../../services/CartManager';
import Item from '../../model/Item';
import CartItem from '../../model/CartItem';
import userManager from '../../services/UserManager';
import alertUtils from '../../services/AlertUtils';

const BuyPowerplug: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user) as User;
  const language = useAppSelector((state) => state.user.language);
  const [searchParams] = useSearchParams();

  const [cartHasPlan, setCartHasPlan] = useState(false) as any;
  const [powerplugProduct, setPowerplugProduct] = useState(null) as any;
  const [powerplugPrice, setPowerplugPrice] = useState(null) as any;
  const [powerplugCount, setPowerplugCount] = useState(0);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!user) navigate('/login');

    const fromParam = searchParams.get('from');
    if (fromParam === 'checkoutCancelled') {
      cartManager.clearCart();
    }

    if (cartManager.getCart().items.find((cartItem: CartItem) => cartItem.product.metadata.hasOwnProperty('plan'))) setCartHasPlan(true);

    setBusy(true);
    getPowerplugProductAndCount().then(() => {
      setBusy(false);
    });
  }, []);

  /**
   * Handler that retrieves the Powerplug product from Stripe and the count in the Cart.
   */
  const getPowerplugProductAndCount = async () => {
    try {
      const products = await stripeManager.listStripeDevices();
      const powerplug = products.find((product: Stripe.Product) => product.metadata.device === 'powerplug') as Stripe.Product;
      if (!powerplug) throw new Error('Unable to find Stripe Product for Powerplug!');

      const price = await stripeManager.retrieveStripePrice(powerplug.default_price as string) as Stripe.Price;
      if (!price) throw new Error('Unable to retrieve Powerplug Stripe Price!');

      await Promise.all([setPowerplugProduct(powerplug), setPowerplugPrice(price)]);
      const powerplugCartItem = cartManager.getCart().items.find((cartItem: CartItem) => cartItem.product.id === powerplug.id);
      if (powerplugCartItem) setPowerplugCount(powerplugCartItem.quantity);
    } catch (error: any) {
      console.error(error);
      const details = { component: 'BuyPowerplug', action: 'getPowerplugProductAndCount', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  const incrementPowerplugCount = () => {
    cartManager.addItem(powerplugProduct);
    setPowerplugCount(powerplugCount + 1);
  };

  const decrementPowerplugCount = () => {
    if (!powerplugCount) return;
    cartManager.removeItem(powerplugProduct, 1);
    setPowerplugCount(powerplugCount - 1);
  };

  /**
   * Create a Stripe Checkout Session with current cart items and access it
   */
  const checkout = async () => {
    try {
      setBusy(true);
      const items: Item[] = [];
      let mode: 'payment' | 'subscription' = 'payment';
      let cancelPath = 'buy-powerplug';

      // Convert CartItems to Stripe Item and check if transaction is a subscription or a payment
      cartManager.getCart().items.map((cartItem: CartItem) => {
        if (mode === 'payment' && cartItem.product.metadata.hasOwnProperty('plan')) {
          mode = 'subscription';
          cancelPath = 'choose-plan';
        }
        return items.push({ price: cartItem.product.default_price, quantity: cartItem.quantity } as Item);
      });

      if (user.stripeCustomer && user.stripeCustomer.activeCheckoutSessionId) await stripeManager.expireUserActiveCheckoutSession(user.userId);
      const session = await stripeManager.createCheckoutSession(user.userId, items, mode, '/dashboard', `/${cancelPath}?from=checkoutCancelled`);
      await userManager.updateUserData(user.userId, { stripeCustomer: { activeCheckoutSessionId: session.id } });

      window.location.replace(session.url);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'BuyPowerplug', action: 'checkout', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotCheckout), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div className=" buy-powerplug-page content">
      <Sidebar isMenu />
      <Spinner show={busy} />
      <div className="main-content">
        <div>
          <section className="buyPowerplug__cols-wrapper">
            <div className="buyPowerplug__left-col">
              <h1>{utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.title)}</h1>
              <div className="buyPowerplug__icon-text-wrapper">
                <CheckIcon style={{ width: '21px', height: '21px' }} />
                <p className="buyPowerplug__advantages-text">
                  {utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.sellingPoints[0])}
                </p>
              </div>
              <div className="buyPowerplug__icon-text-wrapper">
                <CheckIcon style={{ width: '21px', height: '21px' }} />
                <p className="buyPowerplug__advantages-text">
                  {utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.sellingPoints[1])}
                </p>
              </div>
              <div className="buyPowerplug__icon-text-wrapper">
                <CheckIcon style={{ width: '21px', height: '21px' }} />
                <p className="buyPowerplug__advantages-text">
                  {utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.sellingPoints[1])}
                </p>
              </div> <div className="buyPowerplug__icon-text-wrapper">
                <CheckIcon style={{ width: '21px', height: '21px' }} />
                <p className="buyPowerplug__advantages-text">
                  {utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.sellingPoints[1])}
                </p>
              </div>
            </div>
            <div className="buyPowerplug__right-col">
              <div className="buyPowerplug__powerplug-wrapper">
                <img src={Powerplug} alt="powerplug-image" />
              </div>
            </div>
          </section>
          <section className="buyPowerplug__bag-wrapper">
            <div className="buyPowerplug__add-bag-wrapper">
              <div className="buyPowerplug__bag-counter-wrapper">
                <div>
                  <p>
                    {
                      powerplugPrice !== null
                        ? (powerplugPrice.unit_amount / 100) * powerplugCount
                        : '0'
                    }$
                  </p>
                </div>
                <div>
                  <button
                    type='button'
                    disabled={!powerplugProduct}
                    onClick={decrementPowerplugCount}
                    className="bag-btn"
                  >
                    <FaMinus style={{ width: '10px', height: '10px', color: 'var(--dark)' }} />
                  </button>
                </div>
                <div>
                  <div className="bag-counter">{powerplugCount}</div>
                </div>
                <div>
                  <button
                    type='button'
                    disabled={!powerplugProduct}
                    onClick={() => incrementPowerplugCount()}
                    className="bag-btn"
                  >
                    <FaPlus style={{ width: '10px', height: '10px', color: 'var(--dark)' }} />
                  </button>
                </div>

              </div>
              <MtpButton
                expand="block"
                onClick={checkout}
                disabled={!cartHasPlan && !powerplugCount}>
                {utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.toCheckout)}</MtpButton>
            </div>
            {
              !cartHasPlan
              && <div className='buyPowerplug__bag-cancel-wrapper'>
                <MtpButton
                  fill="outline"
                  color="mtp-red"
                  onClick={() => navigate('/dashboard')}>
                  {utils.getTranslation(language, translator.pages.authPages.register.buyPowerplug.skip)}</MtpButton>
              </div>
            }
          </section>
        </div>
      </div>
    </div>
  );
};

export default BuyPowerplug;
