import './Sidebar.css';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import LoopIcon from '@mui/icons-material/Loop';
import LogoFR from '../../assets/img/Logo_rectangle_fondnoir_FR.png';
import LogoEN from '../../assets/img/Logo_FTransparent.png';
import { ReactComponent as FamilyIcon } from '../../assets/img/family-icon.svg';
// import { ReactComponent as CartIcon } from '../../assets/img/cart-icon.svg';
import { ReactComponent as ProfilIcon } from '../../assets/img/profil-icon.svg';
import { ReactComponent as PlugIcon } from '../../assets/img/plug-icon.svg';
import userManager from '../../services/UserManager';
import translator from '../../assets/translator.json';

import User from '../../model/User';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import utils from '../../services/Utils';
import AvailableLanguages from '../../model/enums/AvailableLanguages';
import MtpButton from '../mtpButton/mtpButton';
import Hamburger from '../hamburger/hamburger';
import { updateLanguage } from '../../store/slices/UserSlice';
// import FamilyRoles from '../../model/enums/FamilyRoles';
import alertUtils from '../../services/AlertUtils';
import CustomModal from '../customModal/CustomModal';

interface propsContainer {
  isMenu?: boolean,
}

const Sidebar: React.FC<propsContainer> = ({ isMenu }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user) as User | any;
  const language = useAppSelector((state) => state.user.language) as string | any;
  // const role = useAppSelector((state) => state.user.role);

  const [navOpen, setnavOpen] = useState(false);
  const [menuBusy, setMenuBusy] = useState(false);
  const [showNotReadyModal, setShowNotReadyModal] = useState(false);

  const toggleNotReadyModal = () => setShowNotReadyModal(!showNotReadyModal);

  const toggleMenu = (e: any) => {
    if (menuBusy) return;

    setMenuBusy(true);
    if (!navOpen) document.body.classList.add('nav-open');
    else document.body.classList.remove('nav-open');

    setnavOpen(!navOpen);

    // ? Can we do this some other way? Events calling twice elsewise
    setTimeout(() => {
      setMenuBusy(false);
    }, 1);
  };

  const changeLanguage = async () => {
    try {
      let newLanguage = AvailableLanguages.DEFAULT;

      if (user) {
        newLanguage = user?.language === AvailableLanguages.EN ? AvailableLanguages.FR : AvailableLanguages.EN;
        await userManager.updateUserData(user?.userId, {
          language: newLanguage,
        });
      } else if (language) {
        newLanguage = language === AvailableLanguages.EN ? AvailableLanguages.FR : AvailableLanguages.EN;
      }
      dispatch(updateLanguage(newLanguage));
    } catch (error: any) {
      console.error(error);
      const details = { component: 'Sidebar', action: 'changeLanguage', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotReauthenticate), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  const logout = async () => {
    try {
      await userManager.logout(dispatch);
    } catch (error: any) {
      console.error(error);
      const details = { component: 'Sidebar', action: 'logout', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotLogout), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <>
      {
        isMenu
          ? <div className="sidebar__wrapper">
            <div onClick={() => navigate('/dashboard')} className="sidebar__logo" >
              {
                language === AvailableLanguages.EN
                  ? <img src={LogoEN} />
                  : <img src={LogoFR} />
              }
            </div>
            <Hamburger onClick={(e: any) => toggleMenu(e)} />
            <nav className={`sidebar-nav ${navOpen ? ' open' : ''}`}>
              <div className="sidebar-menu">
                <div className={`sidebar-menu-item ${location.pathname === '/dashboard' ? 'selected' : ''}`} onClick={() => navigate('/dashboard')}>
                  <div className="icon-wrapper">
                    <ProfilIcon />
                  </div>
                  {utils.getTranslation(language, translator.menu.profile)}
                  <div className="ext-icon-wrapper"></div>
                </div>
                <div className={`sidebar-menu-item ${location.pathname === '/family' ? 'selected' : ''}`} onClick={() => navigate('/family')}>
                  <div className="icon-wrapper">
                    <FamilyIcon />
                  </div>
                  {utils.getTranslation(language, translator.menu.family)}
                  <div className="ext-icon-wrapper"></div>
                </div>
                <div className={`sidebar-menu-item ${location.pathname === '/devices' ? 'selected' : ''}`} onClick={() => navigate('/devices')}>
                  <div className="icon-wrapper">
                    <PlugIcon />
                  </div>
                  {utils.getTranslation(language, translator.menu.devices)}
                  <div className="ext-icon-wrapper"></div>
                </div>
                {/* {role === FamilyRoles.ADMIN
                  && <div className={`sidebar-menu-item ${location.pathname === '/subscription' ? 'selected' : ''}`} onClick={() => navigate('/subscription')}>
                    <div className="icon-wrapper">
                      <CartIcon />
                    </div>
                    {utils.getTranslation(language, translator.menu.subscription)}
                    <div className="ext-icon-wrapper"></div>
                  </div>} */}
                <div className="sidebar-menu__buttons">
                  <MtpButton size="small" expand="narrow" onClick={() => changeLanguage()} >{utils.getTranslation(language, translator.menu.language)}</MtpButton>
                  <div className="sidebar-logout show-xsmall">
                    <MtpButton
                      size="small"
                      fill="outline"
                      color="mtp-red"
                      expand="block"
                      onClick={() => logout()}
                    >{utils.getTranslation(language, translator.menu.logout)}</MtpButton>
                  </div>
                </div>
              </div>
              <div className="sidebar-logout hide-small">
                <MtpButton fill="outline" color="mtp-red" expand="block" onClick={() => logout()}>{utils.getTranslation(language, translator.menu.logout)}</MtpButton>
              </div>
              <div className="sidebar-logout show-small hide-xsmall">
                <MtpButton
                  size="small"
                  fill="outline"
                  color="mtp-red"
                  expand="block"
                  onClick={() => logout()}
                >{utils.getTranslation(language, translator.menu.logout)}</MtpButton>
              </div>
              <CustomModal
                title={utils.getTranslation(language, translator.pages.devices.notReady.title)}
                show={showNotReadyModal}
                toggle={toggleNotReadyModal}>
                <p>{utils.getTranslation(language, translator.pages.devices.notReady.description)}</p>
              </CustomModal>
            </nav>
          </div >
          : <div className="sidebar__wrapper sidebar__no-menu">
            <div className="sidebar__logo" >
              {
                language === AvailableLanguages.EN
                  ? <img src={LogoEN} />
                  : <img src={LogoFR} />
              }
            </div>
            <div className="sidebar__language-btn-wrapper">
              <MtpButton size="small" expand="narrow" onClick={() => changeLanguage()} >
                <div className="switch-lang-btn">
                  <LoopIcon/>
                  {utils.getTranslation(language, translator.menu.language)}
                </div>
              </MtpButton>
            </div>
          </div>

      }
    </>
  );
};

export default Sidebar;
