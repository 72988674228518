import './mtpButton.css';

interface propsContainer {
  color?: string,
  fill?: string,
  expand?: string,
  size?: string,
  type?: any,
  disabled?: boolean
  onClick?: () => any // ?
}

const MtpButton: React.FC<propsContainer> = ({
  color, fill, expand, size, children, ...props
}) => (
  <button disabled {...props} type={props.type} className={ `mtp-btn ${color} ${fill} ${expand}${size ? ` ${size}` : ''}` } onClick={props.onClick}>
    <div className="mtp-btn__bg-wrapper"></div>
    <span className="mtp-btn__text">{ children }</span>
  </button>
);

MtpButton.defaultProps = {
  color: 'light',
  fill: 'solid',
  expand: 'inline-block',
  size: '',
  type: 'button',
  disabled: false,
};

export default MtpButton;
