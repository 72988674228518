enum DefaultRules {
  MAX_CONSECUTIVE_GAMING = 3600,
  PAUSE_LENGTH = 1800,
  GAMING_THRESHOLD = 300,
  SPORT_GAMING_RATIO_SPORT = 2,
  SPORT_GAMING_RATIO_GAMING = 1,
  WEEKLY_GAMING_GOAL = 18000,
  WEEKLY_SPORT_GOAL = 25200,
  WEEKLY_FREE_GAMING = 10800,
}

export default DefaultRules;
