/* eslint-disable import/no-cycle */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-async-promise-executor */
import { db, functions } from '../FirebaseConfig';
import Family from '../model/Family';
import PricingPlans from '../model/enums/PricingPlans';
import FamilyRoles from '../model/enums/FamilyRoles';
import FamilyMember from '../model/FamilyMember';
import WeekDays from '../model/enums/WeekDays';

class FamilyManager {
  /**
   * Get a Firebase Firestore Family document by its familyId.
   *
   * @param {string} familyId The familyId of the Family to retrieve.
   * @returns {Promise<Family>} The retrieved Family
   */
  getFamilyById = async (familyId: string): Promise<Family> => {
    const familyRef = await db.collection('Families').doc(familyId).get();
    return familyRef.data() as Family;
  };

  /**
   * Retrieves a Family by it's owner property.
   *
   * @param owner The userId of the Family's owner.
   * @returns {Promise<Family | undefined>} The retrieved Family.
   */
  getFamilyByOwner = async (owner: string): Promise<Family | undefined> => {
    const getFamilyByOwner = functions.httpsCallable('family-getFamilyByOwner');
    const response = await getFamilyByOwner({ owner });
    return response.data as Family;
  };

  /**
   * Requests the API to list all the FamilyMembers of a specified Family.
   *
   * @param {string} familyId
   * @returns {Promise<FamilyMember[]>}
   */
  getFamilyMembers = async (familyId: string): Promise<FamilyMember[]> => {
    const getFamilyMembers = functions.httpsCallable('family-getFamilyMembers');
    const response = await getFamilyMembers({ familyId });
    return response.data as FamilyMember[];
  };

  /**
   * Retrieves the role of a specified user in a specified family.
   *
   * @param {string} userId
   * @param {string} familyId
   * @returns {Promise<string>} the role of the user in family
   */
  getFamilyMemberRole = async (userId: string, familyId: string): Promise<string> => {
    const familyMemberRef = await db.collection('Families').doc(familyId).collection('Members').doc(userId)
      .get();
    return familyMemberRef.data()?.role;
  };

  /**
   * Retrieves a family with a specific familyIdentifier
   *
   * @param {string} familyIdentifier
   * @returns {Family} The family
   */
  getFamilyByIdentifier = async (familyIdentifier: string): Promise<Family> => {
    const getFamilyByIdentifier = functions.httpsCallable('family-getFamilyByIdentifier');
    const response = await getFamilyByIdentifier({ familyIdentifier });
    return response.data as Family;
  };

  /**
   * Requests the API to create a Family.
   *
   * @param {string} userId The userId of the User who owns the Family.
   * @param {string} familyIdentifier The familyIdentifier of the new Family.
   * @param {string} familyName The display name of the new Family.
   * @param {string} familyPassword The password of the new Family.
   * @param {WeekDays} firstDayOfWeek The first day of week of the new Family.
   *
   * @returns {Promise<Family>}
   */
  createFamily = async (userId: string, familyIdentifier: string, familyName: string, familyPassword: string): Promise<Family> => {
    const createFamily = functions.httpsCallable('family-createFamily');
    const response = await createFamily({
      userId, familyIdentifier, familyName, familyPassword, firstDayOfWeek: WeekDays.SUNDAY, pricingPlan: PricingPlans.PREMIUM,
    });
    return response.data as Family;
  };

  /**
   * Requests the API to let a specified User join a Family.
   *
   * @param {string} userId
   * @param {string} familyIdentifier
   * @param {string} familyPassword
   * @param {FamilyRoles} role
   * @returns {Promise<Family>} The newly joined Family id
   */
  joinFamily = async (userId: string, familyIdentifier: string, familyPassword: string, role: FamilyRoles): Promise<Family> => {
    const joinFamily = functions.httpsCallable('family-joinFamily');
    const response = await joinFamily({
      userId, familyIdentifier, familyPassword, role,
    });
    return response.data as Family;
  };

  /**
   * Requests the API to let a specified User join back his own Family.
   *
   * @param {string} userId The userId for whom the Member will be created.
   * @returns {Promise<Family>} The newly rejoined Family.
   */
  joinOwnedFamily = async (userId: string): Promise<Family> => {
    const joinOwnedFamily = functions.httpsCallable('family-joinOwnedFamily');
    const response = await joinOwnedFamily({ userId });
    return response.data as Family;
  };

  /**
   * Requests the API to update the specified family with specified data.
   *
   * @param {string} familyId The familyId of the Family which will be updated.
   * @param {any} data Pass in an anonymous object the family properties you want to update.
   * @returns {Promise<Family>} The updated Family.
   */
  updateFamily = async (familyId: string, data: any): Promise<Family> => {
    const updateFamilyRef = functions.httpsCallable('family-updateFamily');
    const response = await updateFamilyRef({ familyId, ...data });
    return response.data as Family;
  };

  /**
   * Updates specified user's family member data in specified family.
   *
   * @param {string} userId
   * @param {string} familyId
   * @param {any} data
   * @returns {FamilyMember} the updated family member
   */
  updateFamilyMember = async (userId: string, familyId: string, data: any) => {
    const updateUserRef = functions.httpsCallable('family-updateFamilyMember');
    await updateUserRef({ userId, familyId, ...data });
  };

  /**
   * Deletes a family.
   *
   * @param {string} familyId
   */
  deleteFamily = async (familyId: string) => {
    const deleteFamily = functions.httpsCallable('family-deleteFamily');
    const deleteFamilyResponse = await deleteFamily({ familyId });
    return deleteFamilyResponse;
  };

  /**
   * Deletes a family member.
   *
   * @param {string} userId
   * @param {string} familyId
   */
  deleteFamilyMember = async (userId: string, familyId: string) => {
    const deleteFamilyMember = functions.httpsCallable('family-deleteFamilyMember');
    const deleteFamilyMemberResponse = await deleteFamilyMember({ userId, familyId });
    return deleteFamilyMemberResponse;
  };
}

const familyManager = new FamilyManager();
export default familyManager;
