import { Formik, Form, Field } from 'formik';
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Accordion } from 'react-bootstrap';
import {
  Divider, TextField as MUITextField,
} from '@mui/material';
/* import {
  IconButton, InputAdornment
} from '@mui/material'; */
import { TextField } from 'formik-mui';
// import { Edit } from '@mui/icons-material';
// import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import SelectInput from '../../component/formFields/SelectInput';
import MtpButton from '../../component/mtpButton/mtpButton';
import Sidebar from '../../component/sidebar/Sidebar';
import Spinner from '../../component/spinner/Spinner';
import Family from '../../model/Family';
import FamilyMember from '../../model/FamilyMember';
import User from '../../model/User';
import familyManager from '../../services/FamilyManager';
import userManager from '../../services/UserManager';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import { updateFamily as dispatchUpdateFamily } from '../../store/slices/UserSlice';
import './Family.css';
import utils from '../../services/Utils';
import translator from '../../assets/translator.json';
import FamilyRoles from '../../model/enums/FamilyRoles';
import CustomModal from '../../component/customModal/CustomModal';
import Spacer from '../../component/spacer/Spacer';
import alertUtils from '../../services/AlertUtils';

const FamilyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const language = useAppSelector((state) => state.user.language);
  const user = useAppSelector((state) => state.user.user) as User;
  const family = useAppSelector((state) => state.user.family) as Family;
  const role = useAppSelector((state) => state.user.role) as string | null;

  const [busy, setBusy] = useState(true);
  const [familyMembers, setFamilyMembers] = useState([] as any[]);
  const [showRemoveMemberFromFamilyModal, setShowRemoveMemberFromFamilyModal] = useState(false);
  const [showQuitFamilyModal, setShowQuitFamilyModal] = useState(false);
  const [showModifyPlanModal, setShowModifyPlanModal] = useState(false);
  const [showJoinFamilyModal, setShowJoinFamilyModal] = useState(false);

  const toggleRemoveMemberFromFamilyModal = () => {
    setShowRemoveMemberFromFamilyModal(!showRemoveMemberFromFamilyModal);
  };

  const toggleQuitFamilyModal = () => {
    setShowQuitFamilyModal(!showQuitFamilyModal);
  };

  const toggleModifyPlanModal = () => {
    setShowModifyPlanModal(!showModifyPlanModal);
  };

  const toggleJoinFamilyModal = () => {
    setShowJoinFamilyModal(!showJoinFamilyModal);
  };

  const updateFamilyMembersList = async () => {
    try {
      const members: FamilyMember[] = await familyManager.getFamilyMembers(user.familyId);
      const FamilyMembersTemp: any[] = [];
      await Promise.all(
        members.map(async (member: FamilyMember) => {
          const userTemp = await userManager.getUserById(member.userId);

          FamilyMembersTemp.push({
            ...userTemp,
            role: member.role,
          });
        }),
      );
      setFamilyMembers(FamilyMembersTemp);
    } catch (error: any) {
      console.error(error);
      const details = { component: 'Family', action: 'updateFamilyMembersList', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  useEffect(() => {
    loadHandler();
  }, []);

  /**
   * Handler that runs the page's required while a Busy state is set:
   * - Verify user's checkout session
   * - Update the family members list
   */
  const loadHandler = async () => {
    try {
      setBusy(true);
      await Promise.all([utils.verifyCheckoutSession(user), updateFamilyMembersList()]);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Family', action: 'loadHandler', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  // Options for the family role select input
  const familyRolesOptions: any[] = [
    { value: FamilyRoles.PARENT, label: utils.getTranslation(language, translator.models.enums.familyRoles.parent) },
    { value: FamilyRoles.CHILD, label: utils.getTranslation(language, translator.models.enums.familyRoles.child) },
  ];

  /**
   * Handler that, depending on the current Family Pricing Plan:
   * - Sends the User to the ChoosePlan page if the Family is on a Free pricing plan.
   * - Toggles the Modify Plan Modal if the plan is not free.
   */
  /* const modifyPlanClickHandler = () => {
    if (family.plan === PricingPlans.FREE) navigate('/choose-plan');
    else toggleModifyPlanModal();
  }; */

  /**
   * Handler that updates the current User's family.
   *
   * @param {any} values
   */
  const updateFamily = async (values: any) => {
    try {
      setBusy(true);
      const updatedFamily = await familyManager.updateFamily(family.id, {
        name: values.familyName, familyIdentifier: values.familyIdentifier, familyPassword: values.familyPassword,
      });
      dispatch(dispatchUpdateFamily(updatedFamily));
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.familyUpdated), dispatch);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Family', action: 'updateFamily', requestName: error.details?.requestName };
      await alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotUpdate), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  /**
   * Requests the familyManager to update the current family member
   *
   * @param {string} userId
   * @param {any} values
   */
  const updateFamilyMember = async (userId: string, values: any) => {
    try {
      setBusy(true);
      await familyManager.updateFamilyMember(userId, family.id, { ...values });
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Family', action: 'updateFamilyMember', requestName: error.details?.requestName };
      await alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotUpdate), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  /**
   * Requests the familyManager to remove the specified user from the current family
   *
   * @param {string} userId
   */
  const deleteFamilyMember = async (userId: string) => {
    try {
      setBusy(true);
      const newFamily = await familyManager.joinOwnedFamily(userId);
      await familyManager.deleteFamilyMember(userId, family.id);
      await userManager.updateUserData(userId, { familyId: newFamily.id });
      if (user.userId !== userId) await updateFamilyMembersList();
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Family', action: 'deleteFamilyMember', requestName: error.details?.requestName };
      await alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotDelete), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  const joinFamily = async (values: any) => {
    try {
      setBusy(true);
      await familyManager.joinFamily(user.userId, values.familyIdentifier, values.familyPassword, FamilyRoles.CHILD);
      toggleJoinFamilyModal();
      setBusy(false);
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.joinedFamily), dispatch);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Family', action: 'joinFamily', requestName: error.details?.requestName };
      await alertUtils.createErrorAlert(error.message, dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  /**
   * Handler that makes the current User quit the family he is a guest in:
   * - Creates Admin Family Member in owned Family.
   * - Deletes Family Member in current Family.
   * - Updates the User's `familyId`.
   */
  const quitFamily = async () => {
    try {
      setBusy(true);
      const newFamily = await familyManager.joinOwnedFamily(user.userId);
      await familyManager.deleteFamilyMember(user.userId, family.id);
      await userManager.updateUserData(user.userId, { familyId: newFamily.id });
      toggleQuitFamilyModal();
      setBusy(false);
      await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.quitFamily), dispatch);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Family', action: 'quitFamily', requestName: error.details?.requestName };
      await alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotLeaveFamily), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div className="content family-page">
      <Sidebar isMenu />
      <Spinner show={busy} />
      <section className="main-content">
        <div className="family__card">
          <Formik
            initialValues={{
              familyIdentifier: family?.familyIdentifier,
              familyName: family?.name,
              familyPassword: family?.familyPassword,
              pricingPlan: utils.getTranslatedEnumValue(language, 'pricingPlans', family?.plan),
            }}
            validationSchema={yup.object({
              familyIdentifier: yup.string()
                .required(utils.getTranslation(language, translator.formMessages.requiredField)),
              familyName: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
              familyPassword: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
            })}
            onSubmit={async (values, { setSubmitting, setTouched }) => {
              setBusy(true);
              await updateFamily(values);
              setSubmitting(false);
              setTouched({});
              setBusy(false);
            }}
          >
            { /**
               * Render the Family management form
               */
              (formikProps) => (
                <Form>
                  <Field
                    component={TextField}
                    name="familyName"
                    label={utils.getTranslation(language, translator.models.family.name)}
                    disabled={role !== FamilyRoles.ADMIN}
                    fullWidth
                    margin="normal" />
                  <Field
                    component={TextField}
                    name="familyIdentifier"
                    label={utils.getTranslation(language, translator.models.family.familyIdentifier)}
                    disabled={role !== FamilyRoles.ADMIN}
                    fullWidth
                    margin="normal" />
                  <Field
                    component={TextField}
                    name="familyPassword"
                    label={utils.getTranslation(language, translator.models.family.familyPassword)}
                    disabled={role !== FamilyRoles.ADMIN}
                    fullWidth
                    margin="normal" />
                  {/* <Field
                    component={TextField}
                    name="pricingPlan"
                    label={utils.getTranslation(language, translator.models.family.pricingPlan)}
                    disabled
                    fullWidth
                    margin="normal"
                    InputProps={role === FamilyRoles.ADMIN ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="modify your plan"
                            onClick={modifyPlanClickHandler}
                            onMouseDown={(e: any) => e.preventDefault()}>
                            <Edit />
                          </IconButton>
                        </InputAdornment>

                      ),
                    } : null} /> */}
                  <Spacer height={15} />
                  {
                    /**
                     * If user is admin, render the 'modify plan' modal and button and the 'save' button
                     */
                    role === FamilyRoles.ADMIN
                    && <>
                      <MtpButton
                        type="submit"
                        expand="block"
                        disabled={!(formikProps.touched.familyName || formikProps.touched.familyIdentifier || formikProps.touched.familyPassword)}>
                        {utils.getTranslation(language, translator.pages.family.saveFamily)}
                      </MtpButton>
                      <CustomModal
                        show={showModifyPlanModal}
                        toggle={toggleModifyPlanModal}
                        title={utils.getTranslation(language, translator.pages.family.modifyPlan)}>
                        <p>{utils.getTranslation(language, translator.pages.family.modals.modifyPlan)}</p>
                        <Spacer height={30} />
                      </CustomModal>
                    </>
                  }
                  <Spacer height={5} />
                  <Divider sx={{
                    maxWidth: '400px', marginRight: 'auto', marginLeft: 'auto', padding: '0 30px', textAlign: 'center',
                  }}/>
                  <Spacer height={15} />
                  {
                    /**
                     * Render the Join Family button if the user is in his own Family.
                     */
                    (user.userId !== family.owner || familyMembers.length < 2)
                    && <>
                      <MtpButton
                        type="button"
                        expand="block"
                        color="mtp-blue"
                        onClick={toggleJoinFamilyModal}>
                        {utils.getTranslation(language, translator.pages.family.joinFamily)}
                      </MtpButton>
                      <CustomModal
                        show={showJoinFamilyModal}
                        toggle={toggleJoinFamilyModal}
                        title={utils.getTranslation(language, translator.pages.family.joinFamily)}>
                        <Formik
                          initialValues={{
                            familyIdentifier: '',
                            familyPassword: '',
                          }}
                          validationSchema={yup.object({
                            familyIdentifier: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
                            familyPassword: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
                          })}
                          onSubmit={(values, { setSubmitting }) => {
                            joinFamily(values);
                            setSubmitting(false);
                          }}
                        >
                          <Form>
                            <div className="mtp-form-container">
                              <p>{utils.getTranslation(language, translator.pages.family.modals.joinFamily)}</p>
                              <Field
                                component={TextField}
                                name="familyIdentifier"
                                label={utils.getTranslation(language, translator.models.family.familyIdentifier)}
                                margin="normal"
                              />
                              <Field
                                component={TextField}
                                name="familyPassword"
                                label={utils.getTranslation(language, translator.models.family.familyPassword)}
                                margin="normal"
                              />
                              <MtpButton onClick={toggleJoinFamilyModal}>
                                {utils.getTranslation(language, translator.pages.family.modals.cancel)}
                              </MtpButton>
                              <MtpButton type="submit" color="mtp-blue">
                                {utils.getTranslation(language, translator.pages.family.modals.confirm)}
                              </MtpButton>
                            </div>
                          </Form>
                        </Formik >
                      </CustomModal>
                    </>}
                  {
                    /**
                     * Render the quit family button and modal if the User is a Parent in a guest Family.
                     */
                    (user.userId !== family.owner && role === FamilyRoles.PARENT)
                    && <>
                      <MtpButton
                        type="button"
                        expand="block"
                        color="danger"
                        onClick={toggleQuitFamilyModal}>
                        {utils.getTranslation(language, translator.pages.family.quitFamily)}
                      </MtpButton>
                      <CustomModal
                        show={showQuitFamilyModal}
                        toggle={toggleQuitFamilyModal}
                        title={utils.getTranslation(language, translator.pages.family.quitFamily)}
                      >
                        <p>{utils.getTranslation(language, translator.pages.family.modals.quitFamily)}</p>
                        <div className="dashboard__btn-wrapper">
                          <MtpButton onClick={() => toggleQuitFamilyModal()}>
                            {utils.getTranslation(language, translator.pages.family.modals.cancel)}
                          </MtpButton>
                          <MtpButton color="mtp-red" onClick={quitFamily}>
                            {utils.getTranslation(language, translator.pages.family.modals.confirm)}
                          </MtpButton>
                        </div>
                      </CustomModal>
                    </>
                  }

                </Form>
              )}
          </Formik >
        </div>
        <div className="mtp-accordions-wrapper" style={{ width: '100%' }}>
          {
            /**
             * Render all family members
             */
            familyMembers.map((member, key) => <Accordion key={key}>
              <Accordion.Item eventKey={`${key}`} className="mtp-accordion-item">
                <Accordion.Header>
                  <div className="mtp-accordion-header">
                    <div className='mtp-accordion-avatar'>
                      <img src={`./${member.avatarPath || 'assets/avatars/avatar_none.png'}`} alt="avatar" />
                    </div>
                    <div>
                      <h4 className="capitalize">
                        {member.firstname} {member.name}{user.userId === member.userId ? ` (${utils.getTranslation(language, translator.pages.family.you)})` : ''}
                      </h4>
                      <p>{utils.getTranslatedEnumValue(language, 'familyRoles', member.role)}</p>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Spacer height={15} />
                  <div className="mtp-field-wrapper">
                    <MUITextField
                      type="text"
                      variant="outlined"
                      label={utils.getTranslation(language, translator.models.user.email)}
                      value={member.email.replace('@movethenplay.com', '')}
                      fullWidth
                      disabled />
                  </div>
                  {
                    /**
                     * If user is admin and not rendering self, render the 'role modification' form and 'remove from family' button
                     */
                    user.userId !== member.userId
                    && role === FamilyRoles.ADMIN
                    && <Formik
                      initialValues={{
                        role: member.role,
                      }}
                      validationSchema={yup.object({
                        role: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
                      })}
                      onSubmit={async (val, { setSubmitting }) => {
                        setBusy(true);
                        await updateFamilyMember(member.userId, val);
                        await updateFamilyMembersList();
                        setSubmitting(false);
                        setBusy(false);
                        await alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.familyMemberUpdated), dispatch);
                      }}
                    >
                      <Form>
                        <SelectInput
                          id="role"
                          name="role"
                          label={utils.getTranslation(language, translator.pages.family.role)}
                          options={familyRolesOptions}
                        />
                        <MtpButton color='mtp-red' expand='block' size='small' fill='outline' onClick={() => deleteFamilyMember(member.userId)}>
                          {utils.getTranslation(language, translator.pages.family.removeMember)}
                        </MtpButton>
                        <MtpButton type="submit" expand='block' size='small'>
                          {utils.getTranslation(language, translator.pages.family.saveMember)}
                        </MtpButton>
                        <CustomModal
                          show={showRemoveMemberFromFamilyModal}
                          toggle={toggleRemoveMemberFromFamilyModal}
                          title={utils.getTranslation(language, translator.pages.family.removeMember)}>
                          <p>{utils.getTranslation(language, translator.pages.family.modals.removeMember)}</p>
                          <div className="dashboard__btn-wrapper">
                            <MtpButton onClick={() => toggleRemoveMemberFromFamilyModal()}>
                              {utils.getTranslation(language, translator.pages.family.modals.cancel)}
                            </MtpButton>
                            <MtpButton color="mtp-red" onClick={() => deleteFamilyMember(member.userId)}>
                              {utils.getTranslation(language, translator.pages.family.modals.confirm)}
                            </MtpButton>
                          </div>
                        </CustomModal>
                      </Form>
                    </Formik>
                  }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>)}
        </div>
      </section >
    </div >
  );
};

export default FamilyPage;
