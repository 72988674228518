import { Formik, Form, Field } from 'formik';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { TextField, Select } from 'formik-mui';
import { DatePicker } from 'formik-mui-lab';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import * as Sentry from '@sentry/react';
import randomWords from 'random-words';

import { auth } from '../../../FirebaseConfig';
import { useAppSelector, useAppDispatch } from '../../../store/Hooks';
import userManager from '../../../services/UserManager';
import './Register.css';
import Spinner from '../../../component/spinner/Spinner';
import Sidebar from '../../../component/sidebar/Sidebar';
import MtpButton from '../../../component/mtpButton/mtpButton';
import utils from '../../../services/Utils';
import translator from '../../../assets/translator.json';
import Spacer from '../../../component/spacer/Spacer';
import errorManager from '../../../services/ErrorManager';
import emailManager from '../../../services/EmailManager';
import Rules from '../../../model/Rules';
import DefaultRules from '../../../model/enums/DefaultRules';
import familyManager from '../../../services/FamilyManager';
import Gender from '../../../model/enums/Gender';

const Register: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.user.language);
  const [searchParams] = useSearchParams();

  const [busy, setBusy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handler that creates a User account and its required components:
   *
   * - StripeCustomer
   * - Family
   * - Send confirmation email
   *
   * @param {*} values The values entered in the account creation form.
   */
  const createUserAccount = async (values: any) => {
    try {
      setBusy(true);
      const rules: Rules = {
        maxConsecutiveGaming: DefaultRules.MAX_CONSECUTIVE_GAMING,
        gamingThreshold: DefaultRules.GAMING_THRESHOLD,
        pauseLength: DefaultRules.PAUSE_LENGTH,
        sportGamingRatio: {
          gaming: DefaultRules.SPORT_GAMING_RATIO_GAMING,
          sport: DefaultRules.SPORT_GAMING_RATIO_SPORT,
        },
        weeklyGamingGoal: DefaultRules.WEEKLY_GAMING_GOAL,
        weeklySportGoal: DefaultRules.WEEKLY_SPORT_GOAL,
        weeklyFreeGaming: DefaultRules.WEEKLY_FREE_GAMING,
      };

      /**
       * Indicates the offset in minutes between the UTC time and the user time
       * UTC+0 time + offset = the user's time
       * */
      const timezoneOffset = -new Date().getTimezoneOffset();

      await userManager.createAccount(values.firstName, values.name, values.email, values.password, utils.formatDate(new Date(values.birthDate)), rules, language, randomWords({
        exactly: 3, min: 5, max: 10, join: '-',
      }), timezoneOffset, values.gender);
      await emailManager.sendAccountConfirmationEmail();
      setBusy(false);

      const afterParam = searchParams.get('after');
      if (afterParam === 'choose-plan') navigate('/choose-plan');
      else navigate('/dashboard?from=accountCreated');
    } catch (error: any) {
      console.error(error);
      const details = { component: 'Register', action: 'createUserAccount', requestName: error.details?.requestName };
      await errorManager.handleError(error, details, language, dispatch);
      Sentry.captureException(error, { extra: details });

      if (auth.currentUser) {
        const family = await familyManager.getFamilyByOwner(auth.currentUser.uid);
        if (family) await familyManager.deleteFamily(family.id);
        await userManager.deleteFirebaseUser(auth.currentUser);
        await userManager.deleteFirestoreUser(auth.currentUser.uid);
      }

      setBusy(false);
    }
  };

  return (
    <div className="content no-menu">
      <Sidebar />
      <Spinner show={busy} />
      <div className="main-content">
        <Formik
          initialValues={{
            name: '',
            firstName: '',
            email: '',
            birthDate: '01-01-2000'.replace(/-/g, '/'),
            password: '',
            confirmationPassword: '',
            gender: Gender.UNSPECIFIED,
          }}
          validationSchema={yup.object({
            name: yup.string()
              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
            firstName: yup.string(),
            email: yup.string()
              .email(utils.getTranslation(language, translator.formMessages.invalidEmail))
              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
            birthDate: yup.date().typeError(utils.getTranslation(language, translator.formMessages.invalidDate))
              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
            password: yup.string()
              .min(6, utils.getTranslation(language, translator.formMessages.passwordMinLength))
              .matches(/[a-z]/, utils.getTranslation(language, translator.formMessages.passwordRequiresLowercase))
              .matches(/[A-Z]/, utils.getTranslation(language, translator.formMessages.passwordRequiresUppercase))
              .matches(/\d/, utils.getTranslation(language, translator.formMessages.passwordRequiresDigit))
              .matches(/[@$!%*?&]/, utils.getTranslation(language, translator.formMessages.passwordRequiresSpecialChar))
              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
            confirmationPassword: yup.string()
              .oneOf([yup.ref('password'), null], utils.getTranslation(language, translator.formMessages.passwordsMustMatch)),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await createUserAccount(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <div className="mtp-form-container">
              <h1>{utils.getTranslation(language, translator.pages.authPages.register.createAccount.title)}</h1>
              <p>{utils.getTranslation(language, translator.pages.authPages.register.createAccount.abstract)}</p>
              <Field
                component={TextField}
                name="firstName"
                type="text"
                label={utils.getTranslation(language, translator.pages.authPages.register.createAccount.firstName)}
                margin="normal"
                fullWidth
              />
              <Field
                component={TextField}
                name="name"
                type="text"
                label={utils.getTranslation(language, translator.pages.authPages.register.createAccount.lastName)}
                margin="normal"
                fullWidth
              />
              <Field
                component={Select}
                as="select"
                name="gender"
                type="select"
                label={utils.getTranslation(language, translator.pages.authPages.register.createAccount.gender)}
                className="gender-input"
              >
                <MenuItem value={Gender.MALE}>
                  {utils.getTranslation(language, translator.pages.authPages.register.createAccount.genderType.male)}
                </MenuItem>
                <MenuItem value={Gender.FEMALE}>
                  {utils.getTranslation(language, translator.pages.authPages.register.createAccount.genderType.female)}
                </MenuItem>
                <MenuItem value={Gender.UNSPECIFIED}>
                  {utils.getTranslation(language, translator.pages.authPages.register.createAccount.genderType.unspecified)}
                </MenuItem>
              </Field>
              <Field
                component={TextField}
                name="email"
                type="email"
                label={utils.getTranslation(language, translator.models.user.email)}
                margin="normal"
              />
              <Spacer height={10} />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Field
                  component={DatePicker}
                  name="birthDate"
                  label={utils.getTranslation(language, translator.models.user.birthDate)}
                  margin="normal"
                />
              </LocalizationProvider>
              <Spacer height={5} />
              <Field
                component={TextField}
                name="password"
                margin='normal'
                type={showPassword ? 'text' : 'password'}
                label={utils.getTranslation(language, translator.models.user.password)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(e: any) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>

                  ),
                }}
              />
              <Field
                component={TextField}
                name="confirmationPassword"
                margin='normal'
                type={showPassword ? 'text' : 'password'}
                label={utils.getTranslation(language, translator.pages.authPages.register.createAccount.confirmationPassword)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(e: any) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Spacer height={10} />
              <MtpButton expand="block" type="submit">{utils.getTranslation(language, translator.pages.authPages.register.createAccount.submit)}</MtpButton>
              <Link to={'/login'}>
                <div>{utils.getTranslation(language, translator.pages.authPages.register.createAccount.toLogin)}</div>
              </Link>
            </div>
          </Form>
        </Formik >
      </div>
    </div>
  );
};

export default Register;
