import './Spacer.css';

interface ContainerProps {
  responsive?: boolean,
  height?: number,
}

const Spacer: React.FC<ContainerProps> = ({ responsive, height }) => <div
  className={`mtp-spacer ${responsive ? 'responsive' : ''}`}
  style={{ height }}>
</div>;

Spacer.defaultProps = {
  responsive: false,
  height: 30,
};

export default Spacer;
