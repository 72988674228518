const Avatars: string[] = [
  'assets/avatars/avatar_baseball.png',
  'assets/avatars/avatar_basketball.png',
  'assets/avatars/avatar_bike.png',
  'assets/avatars/avatar_football.png',
  'assets/avatars/avatar_gameboy.png',
  'assets/avatars/avatar_hockey.png',
  'assets/avatars/avatar_mice.png',
  'assets/avatars/avatar_playstation.png',
  'assets/avatars/avatar_shoe.png',
  'assets/avatars/avatar_soccer.png',
  'assets/avatars/avatar_swim.png',
  'assets/avatars/avatar_switch.png',
  'assets/avatars/avatar_tennis.png',
  'assets/avatars/avatar_volleyball.png',
  'assets/avatars/avatar_wii.png',
  'assets/avatars/avatar_xbox.png',
];
export default Avatars;
