enum ConsoleTypes {
  // Nintendo
  NES = 'NES',
  SUPER_NES = 'Super NES',
  NINTENDO_64 = 'Nintendo 64',
  GAMECUBE = 'GameCube',
  WII = 'Wii',
  WII_U = 'Wii U',
  SWITCH = 'Switch',
  // PlayStation
  PS1 = 'PlayStation',
  PS2 = 'PlayStation 2',
  PS3 = 'PlayStation 3',
  PS4 = 'PlayStation 4',
  PS5 = 'PlayStation 5',
  // XBOX
  XBOX = 'Xbox',
  XBOX_360 = 'Xbox 360',
  XBOX_ONE = 'Xbox One',
  XBOX_ONE_X = 'Xbox One X',
  XBOX_SERIES_S = 'Xbox Series S',
  XBOX_SERIES_X = 'Xbox Series X'
}

export default ConsoleTypes;
