import utils from '../../services/Utils';
import translator from '../../assets/translator.json';
import { useAppSelector } from '../../store/Hooks';
import './ComingSoon.css';

const ComingSoon: React.FC = () => {
  const language = useAppSelector((state) => state.user.language);

  return (
    <div className='coming-soon-section'>
      <img className='coming-soon-power-plug' src='assets/power-plug.svg' alt="powerplug image" />
      <h1>{utils.getTranslation(language, translator.pages.devices.notReady.title)}</h1>
      <p>{utils.getTranslation(language, translator.pages.devices.notReady.description)}</p>
    </div>
  );
};

export default ComingSoon;
