import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TextField } from 'formik-mui';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Sentry from '@sentry/react';
import userManager from '../../../services/UserManager';
import Spinner from '../../../component/spinner/Spinner';
import Sidebar from '../../../component/sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import User from '../../../model/User';
import MtpButton from '../../../component/mtpButton/mtpButton';
import './Login.css';
import utils from '../../../services/Utils';
import translator from '../../../assets/translator.json';
import Spacer from '../../../component/spacer/Spacer';
import errorManager from '../../../services/ErrorManager';
import alertUtils from '../../../services/AlertUtils';

const Login: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as unknown as User;
  const language = useAppSelector((state) => state.user.language);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [busy, setBusy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /** Check for authentication */
  useEffect(() => {
    if (user) navigate('/dashboard');
  }, [user]);

  /** Manage alerts on load */
  useEffect(() => {
    const fromParam = searchParams.get('from');
    if (fromParam === 'passwordReset') {
      alertUtils.createSuccessAlert(utils.getTranslation(language, translator.successMessages.passwordReset), dispatch);
    }
  }, []);

  const login = async (values: any) => {
    try {
      setBusy(true);
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const email = values.email + (values.email.includes('@') ? '' : '@movethenplay.com');
      if (!emailPattern.test(email)) throw new Error(utils.getTranslation(language, translator.errorMessages.general.invalidEmail));
      await userManager.login(email, values.password);
      setBusy(false);
      navigate('/dashboard');
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Login', action: 'login', requestName: error.details?.requestName };
      await errorManager.handleError(error, details, language, dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div id="login-page" className="content no-menu">
      <Sidebar />
      <Spinner show={busy} />
      <div className="main-content">
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={yup.object({
            email: yup.string()
              .required(utils.getTranslation(language, translator.formMessages.requiredField)),
            password: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
          })}
          onSubmit={(values, { setSubmitting }) => {
            login(values).then(() => setSubmitting(false));
          }}
        >
          <Form>
            <div className="mtp-form-container">
              <h1>{utils.getTranslation(language, translator.pages.authPages.login.title)}</h1>
              <p>{utils.getTranslation(language, translator.pages.authPages.login.abstract)}</p>
              <Field
                component={TextField}
                name="email"
                type="text"
                label={utils.getTranslation(language, translator.models.user.emailOrUsername)}
                margin='normal'
                fullWidth
              />
              <Field
                component={TextField}
                name="password"
                margin='normal'
                type={showPassword ? 'text' : 'password'}
                label={utils.getTranslation(language, translator.models.user.password)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(e: any) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Spacer height={10} />
              <MtpButton type='submit' expand="block">{utils.getTranslation(language, translator.pages.authPages.login.submit)}</MtpButton>
            </div>
          </Form>
        </Formik >
        <Link to={'/register'}>
          <div>{utils.getTranslation(language, translator.pages.authPages.login.toRegister)}</div>
        </Link>
        <Link to={'/forgot-password'}>
          <div>{utils.getTranslation(language, translator.pages.authPages.login.toForgotPassword)}</div>
        </Link>
      </div>
    </div>
  );
};
export default Login;
