import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './CustomAlert.css';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { removeAlert } from '../../store/slices/AlertsSlice';
import { useAppDispatch } from '../../store/Hooks';

interface ContainerProps {
  alert: any,
}
const CustomAlert: React.FC<ContainerProps> = ({ alert }) => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (alert.dismiss) setShow(false);
  }, [alert]);

  const toggleAlert = () => {
    try {
      setShow(!show);
      dispatch(removeAlert(alert.id));
    } catch (error: any) {
      console.error(error);
      const details = { component: 'CustomAlert', action: 'toggleAlert', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <Collapse
      sx={{
        maxWidth: '800px',
      }}
      in={show}>
      <Alert
        variant="filled"
        severity={alert.severity}
        sx={{
          width: '100%',
          maxWidth: '800px',
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              toggleAlert();
            }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {alert.message}
      </Alert>
    </Collapse>
  );
};

export default CustomAlert;
