import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import Sidebar from '../../component/sidebar/Sidebar';
import Spinner from '../../component/spinner/Spinner';
import powerplugManager from '../../services/PowerplugManager';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import utils from '../../services/Utils';
import translator from '../../assets/translator.json';
import './Devices.css';
import MtpButton from '../../component/mtpButton/mtpButton';
import SelectInput from '../../component/formFields/SelectInput';
import ConsoleTypes from '../../model/enums/ConsoleTypes';
import FamilyRoles from '../../model/enums/FamilyRoles';
import Spacer from '../../component/spacer/Spacer';
import PricingPlans from '../../model/enums/PricingPlans';
import Powerplug from '../../model/Powerplug';
import PowerplugStatus from '../../model/enums/PowerplugStatus';
import alertUtils from '../../services/AlertUtils';
import ComingSoon from '../../component/comingSoon/ComingSoon';

const Devices: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.user.language);
  const user = useAppSelector((state) => state.user.user) as any;
  const role = useAppSelector((state) => state.user.role) as string | null;
  const family = useAppSelector((state) => state.user.family) as any;
  const isReady = false;

  const [busy, setBusy] = useState(true);
  const [devices, setDevices] = useState<Powerplug[]>([]);
  const consoleOptions = utils.optionsFromEnum(ConsoleTypes);

  const parseDeviceConsole = (videoGamesConsole: any) => (((Object).values(ConsoleTypes).includes(videoGamesConsole)) ? videoGamesConsole : '');

  const updateDevicesList = async () => {
    try {
      if (!user.familyId) return;
      const devicesTemp = await powerplugManager.getPowerplugsByFamily(family.id);
      setDevices(devicesTemp);
    } catch (error: any) {
      console.error(error);
      const details = { component: 'Devices', action: 'updateDevicesList', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  const updateDevice = async (id: string, val: any) => {
    try {
      setBusy(true);
      await powerplugManager.updatePowerplug(id, { ...val });
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Devices', action: 'updateDevice', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotUpdate), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  const deleteDevice = async (id: string) => {
    try {
      setBusy(true);
      await powerplugManager.deletePowerplug(id);
      await updateDevicesList();
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Devices', action: 'deleteDevice', requestName: error.details?.requestName };
      alertUtils.createErrorAlert(utils.getTranslation(language, translator.errorMessages.couldNotDelete), dispatch);
      Sentry.captureException(error, { extra: details });
    }
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const loadHandler = async () => {
    try {
      setBusy(true);
      await Promise.all([utils.verifyCheckoutSession(user), updateDevicesList()]);
      setBusy(false);
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const details = { component: 'Devices', action: 'loadHandler', requestName: error.details?.requestName };
      Sentry.captureException(error, { extra: details });
    }
  };

  return (
    <div className="content">
      <Sidebar isMenu />
      <Spinner show={busy} />
      {isReady
        ? <section className="main-content mtp-devices">
          <div className="mtp-devices__cta">
            <h2>{utils.getTranslation(language, translator.pages.devices.cta.title)}</h2>
            {
              /** If family has free plan, render a warning */
              family.plan === PricingPlans.FREE
              && <>
                <p>{utils.getTranslation(language, translator.pages.devices.cta.freePlanWarning)}</p>
                <Spacer height={10} />
              </>
            }
            <MtpButton expand="block" onClick={() => navigate('/buy-powerplug')}>{utils.getTranslation(language, translator.pages.devices.cta.button)}</MtpButton>
          </div>
          {devices.map((device, key: any) => (
            <Accordion className="device" key={key}>
              <Formik
                initialValues={{
                  name: device.name,
                  console: parseDeviceConsole(device.console),
                }}
                validationSchema={yup.object({
                  name: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
                  console: yup.string().required(utils.getTranslation(language, translator.formMessages.requiredField)),
                })}
                onSubmit={async (val, { setSubmitting }) => {
                  setBusy(true);
                  await updateDevice(device.id, val);
                  await updateDevicesList();
                  setSubmitting(false);
                  setBusy(false);
                }}
              >
                <Form>
                  <Accordion.Item className="mtp-accordion-item" eventKey={`${key}`}>
                    <Accordion.Header>
                      <div className="mtp-accordion-header">
                        <div
                          className="mtp-device-status"
                          style={{
                            width: '16px', height: '16px', borderRadius: '100%', backgroundColor: `var(--${device.status === PowerplugStatus.OFFLINE ? 'danger' : 'success'})`,
                          }}
                        ></div>
                        <div className="mtp-device-heading">
                          <h4>{device.name}</h4>
                          <p>
                            {device.status === PowerplugStatus.OFFLINE
                              && utils.getTranslation(language, translator.models.enums.deviceStatus.offline)}
                            {device.status === PowerplugStatus.ONLINE
                              && utils.getTranslation(language, translator.models.enums.deviceStatus.online)}
                            {device.status === PowerplugStatus.ACTIVE
                              && utils.getTranslation(language, translator.models.enums.deviceStatus.active)}
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Field
                        component={TextField}
                        name="name"
                        label={utils.getTranslation(language, translator.pages.devices.deviceName)}
                        disabled={role !== FamilyRoles.ADMIN}
                        margin="normal"
                        fullWidth />
                      <Spacer height={10} />
                      <SelectInput
                        id="console"
                        name="console"
                        label={utils.getTranslation(language, translator.pages.devices.whichConsole)}
                        disabled={role !== FamilyRoles.ADMIN}
                        options={consoleOptions}
                      />
                      {
                        /**
                         * If user is admin, render the 'delete device' and 'save device' buttons
                         */
                        role === FamilyRoles.ADMIN
                        && <div className="btn-wrapper">
                          <MtpButton
                            fill="outline"
                            color="mtp-red"
                            expand="block"
                            onClick={() => deleteDevice(device.id)}>
                            {utils.getTranslation(language, translator.pages.devices.deleteDevice)}</MtpButton>
                          <MtpButton
                            type="submit"
                            expand="block">
                            {utils.getTranslation(language, translator.pages.devices.saveDevice)}</MtpButton>
                        </div>
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Form>
              </Formik>
            </Accordion>
          ))
          }
        </section>
        : <section className="main-content">
          <ComingSoon />
        </section>
      }
    </div >
  );
};
export default Devices;
