import firebase from 'firebase/app';
import { auth, db, functions } from '../FirebaseConfig';
import Rules from '../model/Rules';
import User from '../model/User';
import { updateFamily, updateFirebaseUser, updateUser } from '../store/slices/UserSlice';
import Gender from '../model/enums/Gender';

class UserManager {
  subscribers = [] as any;

  /**
   * Signs in a User to Firebase with email and password.
   *
   * @param {string} email
   * @param {string} password
   */
  login = async (email: string, password: string) => {
    await auth.signInWithEmailAndPassword(email, password);
  };

  /**
   * Signs out current User from Firebase.
   *
   * @param dispatch
   */
  logout = (dispatch: any) => {
    auth.signOut();
    this.subscribers.forEach((subscriber: any) => subscriber());
    dispatch(updateUser(null));
    dispatch(updateFirebaseUser(null));
    dispatch(updateFamily(null));
  };

  /**
   * Requests the API to create a User Account
   *
   * @param {string} name
   * @param {string} email
   * @param {string} password
   * @param {Date} birthDate
   * @param rules
   * @param {string} language
   * @param familyPassword
   * @param timezone the offset between the user's timezone and UTC
   * @returns {Promise<User>} The newly created User
   */
  createAccount = async (firstName: string,
    name: string,
    email: string,
    password: string,
    birthDate: string,
    rules: Rules,
    language: string,
    familyPassword: string,
    // TODO: if the second call (functions.httpsCallable('user-create...) returns an error, it doesn't cancel the first call for creating an account in auth
    timezone: number,
    gender: Gender): Promise<User> => {
    const firebaseUser = await auth.createUserWithEmailAndPassword(email, password);
    const createUserAccount = functions.httpsCallable('user-createUserAccount');
    const response = await createUserAccount({
      userId: firebaseUser.user?.uid,
      email,
      firstName,
      name,
      birthDate,
      rules,
      language,
      password: familyPassword,
      timezone,
      gender,
    });
    return response.data as User;
  };

  /**
   * Delete a specified Firebase user
   *
   * @param {firebase.User} user
   */
  deleteFirebaseUser = async (user: firebase.User) => {
    if (user === null) throw new Error('There is no current user to delete!');
    await user.delete();
  };

  /**
   * Request the API to delete a Firestore User from a specified userId
   *
   * @param {string} userId
   */
  deleteFirestoreUser = async (userId: string) => {
    if (userId === undefined) throw new Error('userId is undefined!');
    const deleteUserAccount = functions.httpsCallable('user-deleteUserAccount');
    return deleteUserAccount({ userId });
  };

  pushSubscribers = (subscriber: any) => {
    this.subscribers.push(subscriber);
  };

  /**
   * Request the API to update specified User Data
   * @param {string} userId The userId of the User to update
   * @param {any} data Pass-in an anonymous object with key-value pairs
   * @returns {Promise<User>} The updated User
   */
  updateUserData = async (userId: string, data: any): Promise<User> => {
    const updateUserRef = functions.httpsCallable('user-updateUser');
    const response = await updateUserRef({ userId, user: data });
    return response.data as User;
  };

  updateUserEmail = async (user: User) => {
    await auth.currentUser?.updateEmail(user.email);
  };

  updatePassword = async (newPassword: string) => {
    await auth.currentUser?.updatePassword(newPassword);
  };

  /**
   * Verify with Firebase Auth if the confirmation code is valid
   *
   * @param {string} code
   * @returns {boolean}
   */
  passwordResetCodeIsValid = async (code: string) => {
    await auth.verifyPasswordResetCode(code);
    return true;
  };

  /**
   * Confirm a password reset with Firebase Authenticator
   *
   * ! Unimplemented for now; using firebase default pages
   *
   * @param {string} code
   * @param {string} newPassword
   */
  resetPassword = async (code: string, newPassword: string) => {
    await auth.confirmPasswordReset(code, newPassword);
  };

  /**
   * Returns a User object from a specified userId
   *
   * @param {string} userId
   * @returns {User} a User object
   */
  getUserById = async (userId: string) => {
    const userRef = await db.collection('Users').doc(userId).get();
    return userRef.data() as User;
  };

  /**
   * Request the API to check if specified current user is SuperAdmin
   *
   * @returns {boolean} if user is super admin
   */
  checkIfSuperAdmin = async () => {
    const checkIfSuperAdminRef = functions.httpsCallable('user-checkIfSuperAdmin');
    const isSuperAdmin = await checkIfSuperAdminRef();
    return isSuperAdmin.data;
  };

  /**
   * Request the Authenticator to refresh the User credentials with a specified password.
   *
   * @param password The password sent by the User
   */
  reauthenticate = async (password: string) => {
    if (!auth.currentUser || !auth.currentUser.email) throw new Error('There is no current user!');
    const credential = firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, password);
    auth.currentUser.reauthenticateWithCredential(credential);
  };
}

const userManager = new UserManager();
export default userManager;
